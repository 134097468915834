import { Dayjs } from 'dayjs';

export const EventFormDefaultValues = {
    eventText: '',
    eventTypeId: '',
    systemSubsystemNode: '',
    repeatEvent: false,
    repeatUnit: 'WEEK',
    repeatDayOfWeek: 'MONDAY',
    repeatMonth: 1,
    repeatDayOfMonth: 1,
    selectedDates: '',
    dueDate: null as Dayjs | null,
    controlDate: null as Dayjs | null,
    eventResponsible: { label: '', value: '' },
};

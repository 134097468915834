import { useNavigate } from 'react-router-dom';

import { EventFormType } from 'types/events/EventFormType';
import { axiosWithAuthHeader } from 'core/http';
import { BY_PROJECT_MANAGER, EVENTS, REPETITIVE_EVENTS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { userRolesPermissions } from '../core/constants/userRolesPermissions';

const useCreateEvent = (riskId?: string) => {
    const navigate = useNavigate();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);

    const createEvent = (data: EventFormType): void => {
        if (!riskId) return;

        axiosWithAuthHeader
            .post(`/${riskId}${EVENTS}`, {
                eventText: data.eventText,
                eventTypeId: data.eventTypeId,
                riskSystemId: data.systemSubsystemNode,
            })
            .then((res: { data: { eventId: number } }) => {
                const { eventId } = res.data;
                if (data.repeatEvent) {
                    createRepeatEvent(data, eventId);
                } else {
                    setSuccessAlertText('Мероприятие успешно создано.');
                    navigate(-1);
                }
            })
            .catch(() => {
                setErrorAlertText('Ошибка при создании мероприятия.');
            });
    };

    const createEventWithExtendedFields = (data: EventFormType): void => {
        if (!riskId) return;

        axiosWithAuthHeader
            .post(`/${riskId}${EVENTS}${BY_PROJECT_MANAGER}`, {
                eventText: data.eventText,
                eventTypeId: data.eventTypeId,
                riskSystemId: data.systemSubsystemNode,
                dueDate: data.dueDate?.format('YYYY-MM-DD HH:mm:ss'),
                controlDate: data.controlDate?.format('YYYY-MM-DD HH:mm:ss'),
                userId: data.eventResponsible?.value,
            })
            .then((res: { data: { eventId: number } }) => {
                const { eventId } = res.data;
                if (data.repeatEvent) {
                    createRepeatEvent(data, eventId);
                } else {
                    setSuccessAlertText('Мероприятие успешно создано.');
                    navigate(-1);
                }
            })
            .catch(() => {
                setErrorAlertText('Ошибка при создании мероприятия.');
            });
    };

    const createRepeatEvent = (data: EventFormType, eventId: number) => {
        axiosWithAuthHeader
            .post(`${REPETITIVE_EVENTS}${eventId}`, {
                repeatUnit: data.repeatUnit,
                isCanceled: false,
                repeatDay: data.repeatDayOfMonth,
                repeatMonth: data.repeatMonth,
                repeatDayOfWeek: data.repeatDayOfWeek,
                selectedDates: data.selectedDates.split(', ').filter((date) => !!date),
            })
            .then(() => {
                setSuccessAlertText('Мероприятие успешно создано.');
                navigate(-1);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при создании мероприятия.');
            });
    };

    const permittedUserRolesToCreate = [
        ...userRolesPermissions.events.createFromDraft,
        ...userRolesPermissions.events.createFromWaitsApproval,
        ...userRolesPermissions.events.createFromResponsibleAppointed,
        ...userRolesPermissions.events.createFromUnderRevisionByResponsible,
        ...userRolesPermissions.events.createFromApprovedByProjectManager,
    ];

    return {
        createEvent,
        permittedUserRolesToCreate,
        createEventWithExtendedFields,
    };
};

export { useCreateEvent };

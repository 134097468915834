import Button from '@mui/material/Button';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import * as React from 'react';

import { Routes } from 'navigation/routes';
import { useRisksPermissions } from 'hooks/useRisksPermissions';
import { RiskStatus } from 'core/constants/RiskStatus';
import { RiskType } from 'types/risks/RiskType';

type Props = {
    selectedRiskId: number | null;
    risks: RiskType[];
    exportCSVRisks: () => void;
    renderButtons?: boolean;
};

const NotificationsActionButtons: FC<Props> = ({ selectedRiskId, risks, exportCSVRisks, renderButtons }) => {
    const navigate = useNavigate();

    const {
        canCreateRisk,
        canEditRiskFromDraft,
        canSendRiskOnApproval,
        canApproveRisk,
        canAddRiskOwner,
        canAssessRisk,
        canApproveRiskAssessment,
        canSendAssessmentForApproval,
        canCheckRiskAssessment,
        canViewRiskEventsPlan,
    } = useRisksPermissions();

    const onCreateRisk = (): void => {
        navigate(Routes.CreateRisk);
    };

    const onSendToApprove = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.SendRiskToApprove}/${selectedRiskId}`);
    };

    const onApprove = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.ApproveRisk}/${selectedRiskId}`);
    };

    const onEditRisk = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.EditRisk}/${selectedRiskId}`);
    };

    const onViewRisk = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.ViewRisk}/${selectedRiskId}`);
    };

    const onViewEvents = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.RiskEvents}/${selectedRiskId}`);
    };

    const onAssessRisk = (): void => {
        if (!selectedRiskId) return;
        navigate(`${Routes.AssessRisk}/${selectedRiskId}`);
    };

    const onAddRiskOwner = () => {
        if (!selectedRiskId) return;
        navigate(`${Routes.AddRiskOwner}/${selectedRiskId}`);
    };

    const onApproveRiskAssessment = () => {
        if (!selectedRiskId) return;
        navigate(`${Routes.ApproveRiskAssessment}/${selectedRiskId}`);
    };

    const onCheckRiskAssessment = () => {
        if (!selectedRiskId) return;
        navigate(`${Routes.CheckRiskAssessment}/${selectedRiskId}`);
    };

    const selectedRisk = useMemo(() => {
        return risks.find((n) => n.id === selectedRiskId);
    }, [selectedRiskId]);

    return (
        <Box sx={{ display: 'flex', marginBottom: 4 }}>
            {renderButtons && (
                <>
                    {canCreateRisk && (
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCreateRisk}>
                                Создать новый риск
                            </Button>
                        </Box>
                    )}
                    {canEditRiskFromDraft && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    !(
                                        selectedRisk?.riskStatus.sysName === RiskStatus.Draft ||
                                        selectedRisk?.riskStatus.sysName === RiskStatus.UnderRevision
                                    )
                                }
                                onClick={onEditRisk}
                            >
                                Редактировать риск
                            </Button>
                        </Box>
                    )}
                    {canSendRiskOnApproval && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    !(
                                        selectedRisk?.riskStatus.sysName === RiskStatus.Draft ||
                                        selectedRisk?.riskStatus.sysName === RiskStatus.UnderRevision
                                    )
                                }
                                onClick={onSendToApprove}
                            >
                                Согласовать риск
                            </Button>
                        </Box>
                    )}
                    {canApproveRisk && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 || selectedRisk?.riskStatus.sysName !== RiskStatus.WaitsApproval
                                }
                                onClick={onApprove}
                            >
                                Согласовать риск для оценки
                            </Button>
                        </Box>
                    )}
                    {canAddRiskOwner && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 || selectedRisk?.riskStatus.sysName !== RiskStatus.Approved
                                }
                                onClick={onAddRiskOwner}
                            >
                                Назначить владельца риска
                            </Button>
                        </Box>
                    )}
                    {canAssessRisk && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    selectedRisk?.riskStatus.sysName !== RiskStatus.RiskOwnerAppointed
                                }
                                onClick={onAssessRisk}
                            >
                                Оценить риск
                            </Button>
                        </Box>
                    )}
                    {canSendAssessmentForApproval && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    selectedRisk?.riskStatus.sysName !== RiskStatus.WaitsAssessment
                                }
                                onClick={onAssessRisk}
                            >
                                Отправить на утверждение
                            </Button>
                        </Box>
                    )}
                    {canApproveRiskAssessment && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    !(
                                        selectedRisk?.riskStatus.sysName === RiskStatus.Assessed ||
                                        selectedRisk?.riskStatus.sysName === RiskStatus.ReturnedToProjectManager
                                    )
                                }
                                onClick={onApproveRiskAssessment}
                            >
                                Утвердить оценку риска
                            </Button>
                        </Box>
                    )}
                    {canCheckRiskAssessment && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    selectedRisk?.riskStatus.sysName !== RiskStatus.AssessmentApproved
                                }
                                onClick={onCheckRiskAssessment}
                            >
                                Проверить оценку риска
                            </Button>
                        </Box>
                    )}
                    {canViewRiskEventsPlan && (
                        <Box mr={'10px'}>
                            <Button
                                variant="outlined"
                                disabled={
                                    risks.length === 0 ||
                                    selectedRisk?.riskStatus.sysName !== RiskStatus.ApprovedByCoordinator
                                }
                                onClick={onViewEvents}
                            >
                                План мероприятий
                            </Button>
                        </Box>
                    )}
                </>
            )}
            <Box mr={'10px'}>
                <Button variant="outlined" disabled={risks.length === 0 || !selectedRiskId} onClick={onViewRisk}>
                    Просмотр
                </Button>
            </Box>
            {renderButtons && (
                <Box mr={'10px'}>
                    <Button variant="outlined" onClick={exportCSVRisks}>
                        Выгрузить CSV файл
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export { NotificationsActionButtons };

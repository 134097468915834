import { useMemo } from 'react';

import { useUserStore } from 'core/store/user/useUserStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';

export const useRisksPermissions = () => {
    const { user } = useUserStore();

    const getUserRolesSysNames = () => {
        if (!user) return [];
        return user.userRoles.map((role) => role.sysName);
    };

    const canViewRisksPage = useMemo(() => {
        return userRolesPermissions.risks.view.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCreateRisk = useMemo(() => {
        return userRolesPermissions.risks.create.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canSendRiskOnApproval = useMemo(() => {
        return userRolesPermissions.risks.sendOnApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canApproveRisk = useMemo(() => {
        return userRolesPermissions.risks.approve.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canAddRiskOwner = useMemo(() => {
        return userRolesPermissions.risks.addRiskOwner.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canAssessRisk = useMemo(() => {
        return userRolesPermissions.risks.assessRisk.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canSendAssessmentForApproval = useMemo(() => {
        return userRolesPermissions.risks.sendAssessmentForApproval.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canApproveRiskAssessment = useMemo(() => {
        return userRolesPermissions.risks.approveRiskAssessment.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canCheckRiskAssessment = useMemo(() => {
        return userRolesPermissions.risks.checkRiskAssessment.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canViewRiskEventsPlan = useMemo(() => {
        return userRolesPermissions.risks.viewRiskEvents.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    const canEditRiskFromDraft = useMemo(() => {
        return userRolesPermissions.risks.editDraft.some((r) => getUserRolesSysNames().includes(r));
    }, [user]);

    return {
        canViewRisksPage,
        canCreateRisk,
        canEditRiskFromDraft,
        canSendRiskOnApproval,
        canApproveRisk,
        canAddRiskOwner,
        canAssessRisk,
        canSendAssessmentForApproval,
        canApproveRiskAssessment,
        canCheckRiskAssessment,
        canViewRiskEventsPlan,
    };
};

import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Dayjs } from 'dayjs';

export const NotificationsFilterDefaultValues = {
    name: '',
    reason: '',
    category: '',
    informationSource: '',
    possibleConsequences: '',
    riskLocation: '',
    comment: '',
    responseTime: '',
    responseUnits: '',
    author: '',
    state: '',
    priority: '',
};

export type UseNotificationsStoreType = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    detectionDate: Dayjs | null;
    paginationPage: number;
    filter: {
        name: string;
        reason: string;
        category: string;
        informationSource: string;
        possibleConsequences: string;
        riskLocation: string;
        comment: string;
        responseTime: string;
        responseUnits: string;
        author: string;
        state: string;
        priority: string;
    };

    resetFilter: () => void;
    setPaginationPage: (page: number) => void;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    setDetectionDate: (date: Dayjs | null) => void;
    setFilter: (key: string, value: string) => void;
};

const useNotificationsStore = create<UseNotificationsStoreType>()(
    persist(
        (set, get) => ({
            dateFrom: null,
            dateTo: null,
            detectionDate: null,
            paginationPage: 0,
            filter: NotificationsFilterDefaultValues,

            setPaginationPage: (page) => set({ paginationPage: page }),
            setDateFrom: (date) => set({ dateFrom: date }),
            setDateTo: (date) => set({ dateTo: date }),
            setDetectionDate: (date: Dayjs | null) => set({ detectionDate: date }),
            setFilter: (key: string, value: string) => {
                const filter = get().filter;
                set({ filter: { ...filter, [key]: value } });
            },
            resetFilter: () => {
                set({ filter: NotificationsFilterDefaultValues });
                set({ detectionDate: null });
            },
        }),
        {
            name: 'notifications-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export { useNotificationsStore };

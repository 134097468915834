import { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

import { UIModal } from 'components/UI/UIModal';
import { axiosWithAuthHeader } from 'core/http';
import { TASK_SET_DUE_DATE, TASKS } from 'core/http/endpoints';
import { UILoader } from 'components/UI/UILoader';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess: (date: string) => void;
    taskId?: number;
    showAutoButton?: boolean;
};

export const TaskDeadlineModal: FC<Props> = ({ isVisible, setIsVisible, taskId, onSuccess, showAutoButton = true }) => {
    const [date, setDate] = useState<Dayjs | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const weekFromNow = dayjs().add(7, 'day');

    useEffect(() => {
        if (!isVisible) {
            setIsLoading(false);
        }
    }, [isVisible]);

    const onSubmit = (date?: Dayjs | null) => {
        setError('');
        if (!date) {
            setError('Выберите дату');
            return;
        }
        if (date < dayjs().startOf('day')) {
            setError('Введите дату не раньше сегодняшней');
            return;
        }

        const dueDate = date.format('DD.MM.YYYY');
        setIsLoading(true);

        if (taskId) {
            // для роли project_manager и его просроченных задач
            axiosWithAuthHeader
                .patch(`${TASKS}/${taskId}${TASK_SET_DUE_DATE}`, { dueDate })
                .then(() => onSuccess(dueDate))
                .catch((error) => console.log('error', error));
        } else {
            onSuccess(dueDate);
        }
    };

    return (
        <UIModal isOpen={isVisible} title={'Укажите срок выполнения задачи'} onClose={() => setIsVisible(false)}>
            {isLoading ? (
                <UILoader height={100} isVisible />
            ) : (
                <div>
                    <DatePicker
                        format="DD.MM.YYYY"
                        label={'Срок выполнения задачи'}
                        slotProps={{
                            textField: {
                                required: true,
                                size: 'small',
                                fullWidth: true,
                                variant: 'outlined',
                            },
                        }}
                        disablePast
                        onChange={(date) => {
                            if (date && date < dayjs().startOf('day')) {
                                setError('Введите дату не раньше сегодняшней');
                            } else {
                                setError('');
                            }
                            setDate(date);
                        }}
                    />
                    <Box sx={{ marginTop: 1 }}>
                        <Typography color={'error'}>{error}</Typography>
                    </Box>
                    {showAutoButton && (
                        <Box sx={{ marginTop: 2 }}>
                            <span>
                                Если оставить поле пустым и нажать кнопку "Автоматически 1 неделю", то система назначит
                                срок в 1 календарную неделю.
                            </span>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', marginTop: 2 }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" type={'submit'} onClick={() => onSubmit(date)}>
                                Назначить указанную дату
                            </Button>
                        </Box>
                        {showAutoButton && (
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={() => onSubmit(weekFromNow)}>
                                    Автоматически 1 неделю
                                </Button>
                            </Box>
                        )}
                    </Box>
                </div>
            )}
        </UIModal>
    );
};

import { FC } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import { AppContainer } from 'components/common/appContainer';
import { useSendNotificationToApprove } from 'hooks/useSendNotificationToApprove';
import { UILoader } from 'components/UI/UILoader';
import { Routes } from 'navigation/routes';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';

const SendNotificationToApproveStep2: FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            comment: '',
            coordinatingEmployee: { label: '', value: '' },
            approvingEmployee: { label: '', value: '' },
        },
        shouldUseNativeValidation: false,
    });

    const {
        notification,
        userSupervisors,
        riskSpecialistSupervisors,
        onSendToApprove,
        deadlineModalVisible,
        setDeadlineRejectModalVisible,
    } = useSendNotificationToApprove(id);

    const onEdit = () => {
        if (!id) return;
        navigate(`${Routes.SendNotificationToApproveWithEdit}/${id}`);
    };

    const onCancel = (): void => {
        navigate(Routes.Notifications);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.sendOnApproval}>
                <div className={'container_thin'}>
                    <h2>Отправка уведомления на согласование</h2>
                    {notification ? (
                        <div>
                            <ViewNotificationComponent notification={notification} />
                            <div className={'fields-container'}>
                                <span>Согласующий сотрудник:</span>
                                <ControlledAutocompleteInput
                                    control={control}
                                    label={'Согласующий сотрудник'}
                                    name={'coordinatingEmployee'}
                                    error={!!errors.coordinatingEmployee}
                                    options={userSupervisors}
                                    required
                                />
                                <span>Утверждающий сотрудник:</span>
                                <ControlledAutocompleteInput
                                    control={control}
                                    label={'Утверждающий сотрудник'}
                                    name={'approvingEmployee'}
                                    error={!!errors.approvingEmployee}
                                    options={riskSpecialistSupervisors}
                                    required
                                />
                                <span>Комментарий для согласования:</span>
                                <ControlledTextInput
                                    name={'comment'}
                                    control={control}
                                    label={'Комментарий для согласования'}
                                    error={!!errors.comment}
                                    multiline
                                />
                            </div>
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={(date) => handleSubmit((data) => onSendToApprove(data, date))()}
                            />
                        </div>
                    ) : (
                        <UILoader isVisible />
                    )}
                    <Box sx={{ display: 'flex', marginBottom: 4 }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" onClick={() => setDeadlineRejectModalVisible(true)}>
                                Отправить на согласование
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onEdit}>
                                Редактировать уведомление
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCancel}>
                                Назад
                            </Button>
                        </Box>
                    </Box>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { SendNotificationToApproveStep2 };

import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import { Paper, styled } from '@mui/material';
import { GridRowsProp, GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridInputRowSelectionModel, GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';

import styles from './UITable.module.scss';

const GridContainer = styled(Paper)({
    '.MuiDataGrid-selectedRowCount': {
        opacity: 0,
    },
    '.table-header': {
        backgroundColor: '#ededed',
    },
    '.MuiDataGrid-columnHeaderDraggableContainer': {
        backgroundColor: '#ededed',
    },
});

// eslint-disable-next-line
type Props<R extends GridValidRowModel = any> = {
    loading: boolean;
    totalCount?: number;
    noRowsText: string;
    rows: GridRowsProp<R>;
    columns: GridColDef<R>[];
    onRowSelectionModelChange: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void;
    rowSelectionModel: GridInputRowSelectionModel;
    onChangePaginationPage: (page: number) => void;
    getCellClassName?: (params: GridCellParams<GridValidRowModel, string, GridValidRowModel>) => string;
};

const UITable: FC<Props> = ({
    loading,
    rows,
    totalCount,
    noRowsText,
    columns,
    rowSelectionModel,
    onRowSelectionModelChange,
    onChangePaginationPage,
    getCellClassName,
}) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 50,
    });

    React.useEffect(() => {
        onChangePaginationPage && onChangePaginationPage(paginationModel.page);
    }, [paginationModel.page]);

    return (
        <div className={styles.table}>
            <GridContainer>
                <DataGrid
                    getRowHeight={() => 'auto'}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: 'Количество записей на странице',
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                        },
                    }}
                    slots={{
                        noRowsOverlay: () => (
                            <Stack height="100px" alignItems="center" justifyContent="center">
                                {noRowsText}
                            </Stack>
                        ),
                        noResultsOverlay: () => (
                            <Stack height="100px" alignItems="center" justifyContent="center">
                                {noRowsText}
                            </Stack>
                        ),
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            padding: '8px 6px !important',
                        },
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            wordBreak: 'break-all',
                            whiteSpace: 'normal',
                        },
                    }}
                    loading={loading}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    pageSizeOptions={[50]}
                    rowCount={totalCount}
                    rowSelectionModel={rowSelectionModel}
                    // @ts-ignore
                    getCellClassName={(params: GridCellParams<GridValidRowModel, string, GridValidRowModel>) => {
                        return getCellClassName ? getCellClassName(params) : '';
                    }}
                    checkboxSelection
                    autoHeight
                    pagination
                    disableMultipleRowSelection
                    onPaginationModelChange={setPaginationModel}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                />
            </GridContainer>
        </div>
    );
};

export { UITable };

import * as React from 'react';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';

import { useRisks } from 'hooks/useRisks';
import { UITable } from 'components/UI/UITable';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { Routes } from 'navigation/routes';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { RisksFilter } from './components/RisksFilter';
import { NotificationsActionButtons } from './components/RisksActionButtons';

const columns: GridColDef[] = [
    {
        field: 'shortName',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Краткое
                <br />
                наименование
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'sourceOfRisk',
        flex: 6.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Источник
                <br />
                возникновения
            </strong>
        ),
    },
    {
        field: 'riskRequirements',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Источник
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'riskClassification',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Классификация
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'objectOfInfluence',
        flex: 7,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Объект
                <br />
                воздействия
            </strong>
        ),
    },
    {
        field: 'lifeCycleStage',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Этап ЖЦИ</strong>,
    },
    {
        field: 'systemSubsystemNode',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Система /<br />
                подсистема /<br />
                узел
            </strong>
        ),
    },
    {
        field: 'staffingSchedule',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Штатное
                <br />
                расписание
            </strong>
        ),
    },
    {
        field: 'reason',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Причина
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'description',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Описание
                <br />
                воздействия
                <br />
                риска
            </strong>
        ),
    },
    {
        field: 'author',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Кем
                <br />
                создано
            </strong>
        ),
    },
    {
        field: 'createdAt',
        flex: 7.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Когда
                <br />
                создано
            </strong>
        ),
    },
    {
        field: 'status',
        flex: 7,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Статус</strong>,
    },
];

type Props = {
    renderButtons?: boolean;
    renderFilter?: boolean;
};

const Risks: FC<Props> = ({ renderButtons, renderFilter }) => {
    const { setOriginUrl } = useAsyncAppStore();

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const {
        risks,
        dateFrom,
        dateTo,
        control,
        setDateTo,
        setDateFrom,
        onReset,
        tableRows,
        onFilter,
        setFilter,
        isFetching,
        exportCSVRisks,
        totalCount,
        setPaginationPage,
    } = useRisks(!!renderFilter);

    useEffect(() => {
        setOriginUrl(Routes.Risks);
    }, []);

    return (
        <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.view}>
            {renderFilter && (
                <>
                    <h2>Риски</h2>
                    <RisksFilter
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        control={control}
                        setDateTo={setDateTo}
                        setDateFrom={setDateFrom}
                        onReset={onReset}
                        onSubmit={onFilter}
                        onChangeFilter={setFilter}
                    />
                </>
            )}
            <NotificationsActionButtons
                risks={risks}
                renderButtons={renderButtons}
                selectedRiskId={+selectedRows[0] || null}
                exportCSVRisks={exportCSVRisks}
            />
            <UITable
                totalCount={totalCount}
                noRowsText={'Нет рисков'}
                rows={tableRows}
                columns={columns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onChangePaginationPage={setPaginationPage}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
            />
        </UserRightsContainer>
    );
};

export { Risks };

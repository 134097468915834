import { create } from 'zustand';
import { IBaseDictionaryEntity } from 'types/IBaseDictionaryEntity';

interface AppState {
    errorAlertText: string;
    successAlertText: string;
    notificationsTotal: number;
    notificationStatuses: IBaseDictionaryEntity[];
    dictionaryPriorities: IBaseDictionaryEntity[];
    dictionaryUserRoles: IBaseDictionaryEntity[];
    dictionaryDepartments: IBaseDictionaryEntity[];
    sourceOfRiskData: IBaseDictionaryEntity[];
    dictionaryClasses: IBaseDictionaryEntity[];
    dictionaryLifecycleStages: IBaseDictionaryEntity[];
    dictionaryRiskSystem: IBaseDictionaryEntity[];
    dictionaryStaffingTable: IBaseDictionaryEntity[];
    dictionaryRiskObjects: IBaseDictionaryEntity[];
    riskStatuses: IBaseDictionaryEntity[];
    dictionaryRiskAssessmentMethod: IBaseDictionaryEntity[];
    notificationsResponseTimeUnits: IBaseDictionaryEntity[];

    setErrorAlertText: (text: string) => void;
    setSuccessAlertText: (text: string) => void;
    setNotificationStatuses: (data: IBaseDictionaryEntity[]) => void;
    setRiskStatuses: (data: IBaseDictionaryEntity[]) => void;
    setNotificationsResponseTimeUnits: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryPriorities: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryUserRoles: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryDepartments: (data: IBaseDictionaryEntity[]) => void;
    setSourceOfRiskData: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryClasses: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryLifecycleStages: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryRiskSystem: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryStaffingTable: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryRiskObjects: (data: IBaseDictionaryEntity[]) => void;
    setDictionaryRiskAssessmentMethod: (data: IBaseDictionaryEntity[]) => void;
    setNotificationsTotal: (data: number) => void;
}

const useAppStore = create<AppState>((set) => ({
    errorAlertText: '',
    successAlertText: '',
    notificationStatuses: [],
    riskStatuses: [],
    notificationsResponseTimeUnits: [],
    dictionaryPriorities: [],
    dictionaryUserRoles: [],
    dictionaryDepartments: [],
    sourceOfRiskData: [],
    dictionaryClasses: [],
    dictionaryLifecycleStages: [],
    dictionaryRiskSystem: [],
    dictionaryStaffingTable: [],
    dictionaryRiskObjects: [],
    dictionaryRiskAssessmentMethod: [],
    notificationsTotal: 0,

    setErrorAlertText: (text: string) => set({ errorAlertText: text }),
    setSuccessAlertText: (text: string) => set({ successAlertText: text }),
    setNotificationStatuses: (data: IBaseDictionaryEntity[]) => set({ notificationStatuses: data }),
    setDictionaryPriorities: (data: IBaseDictionaryEntity[]) => set({ dictionaryPriorities: data }),
    setDictionaryUserRoles: (data: IBaseDictionaryEntity[]) => set({ dictionaryUserRoles: data }),
    setDictionaryDepartments: (data: IBaseDictionaryEntity[]) => set({ dictionaryDepartments: data }),
    setSourceOfRiskData: (data: IBaseDictionaryEntity[]) => set({ sourceOfRiskData: data }),
    setDictionaryClasses: (data: IBaseDictionaryEntity[]) => set({ dictionaryClasses: data }),
    setDictionaryLifecycleStages: (data: IBaseDictionaryEntity[]) => set({ dictionaryLifecycleStages: data }),
    setDictionaryRiskSystem: (data: IBaseDictionaryEntity[]) => set({ dictionaryRiskSystem: data }),
    setDictionaryStaffingTable: (data: IBaseDictionaryEntity[]) => set({ dictionaryStaffingTable: data }),
    setDictionaryRiskObjects: (data: IBaseDictionaryEntity[]) => set({ dictionaryRiskObjects: data }),
    setDictionaryRiskAssessmentMethod: (data: IBaseDictionaryEntity[]) => set({ dictionaryRiskAssessmentMethod: data }),
    setRiskStatuses: (data: IBaseDictionaryEntity[]) => set({ riskStatuses: data }),
    setNotificationsTotal: (data: number) => set({ notificationsTotal: data }),
    setNotificationsResponseTimeUnits: (data: IBaseDictionaryEntity[]) => set({ notificationsResponseTimeUnits: data }),
}));

export { useAppStore };

import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

export const NotificationsTableColumns: GridColDef[] = [
    {
        field: 'name',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Наименование <br /> уведомления
            </strong>
        ),
    },
    {
        field: 'reason',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Причина <br /> риска
            </strong>
        ),
    },
    {
        field: 'category',
        flex: 7,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Объект <br /> воздействия
            </strong>
        ),
    },
    {
        field: 'informationSource',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Источник <br /> риска
            </strong>
        ),
    },
    {
        field: 'detectionDate',
        flex: 6,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Время <br /> обнаружения
            </strong>
        ),
    },
    {
        field: 'possibleConsequences',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Описание <br /> воздействия
            </strong>
        ),
    },
    {
        field: 'riskLocation',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Местоположение <br /> риска
            </strong>
        ),
    },
    {
        field: 'responseTime',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Сроки <br /> реагирования
            </strong>
        ),
    },
    {
        field: 'priority',
        flex: 6,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Приоритет</strong>,
    },
    {
        field: 'creatorName',
        flex: 9.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Кем создано</strong>,
    },
    {
        field: 'createdAt',
        flex: 7.5,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Когда создано</strong>,
    },
    {
        field: 'status',
        flex: 7,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Статус</strong>,
    },
];

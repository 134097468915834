type TreeNode = {
    value: number;
    label: string;
    parentId: number | null;
    childNodes: TreeNode[];
};

type DataType = {
    value: number;
    label: string;
    parentId: number | null;
}[];

export const buildTreeRiskSystem = (data: DataType) => {
    const tree: TreeNode[] = [];
    const lookup: Record<number, TreeNode> = {};

    for (const item of data) {
        lookup[item.value] = { ...item, childNodes: [] };
    }

    for (const item of data) {
        if (item.parentId === null) {
            tree.push(lookup[item.value]);
        } else {
            lookup[item.parentId].childNodes.push(lookup[item.value]);
        }
    }

    return tree;
};

import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { notificationsService } from 'core/services/notificationsService';
import { NotificationType } from 'types/notifications/NotificationType';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { Routes } from 'navigation/routes';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { tasksService } from 'core/services/tasksService';
import { RejectNotificationModal } from '../components/RejectNotificationModal';

const MakeRiskDecision: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const setSuccessAlertText = useAppStore((state) => state.setSuccessAlertText);
    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);

    const [notification, setNotification] = useState<NotificationType>();
    const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);
    const [rejectReason, setRejectReason] = useState<string>('');

    useEffect(() => {
        if (!id) return;

        notificationsService.getNotification(id, (res) => {
            setNotification(res.data as NotificationType);
        });
    }, []);

    const onSearchRisk = (): void => {
        navigate(`${Routes.NotificationsSearchRisk}/${id as string}`);
    };

    const onCreateRisk = (): void => {
        navigate(`${Routes.CreateRisk}?notificationId=${id as string}`);
    };

    const onSubmitRejectForm = (reason: string) => {
        if (!id) return;

        setRejectModalVisible(false);
        setDeadlineRejectModalVisible(true);
        setRejectReason(reason);
    };

    const onReject = (dueDate: string) => {
        if (!id || !rejectReason) return;

        notificationsService.rejectNotification(
            id,
            rejectReason,
            (res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Уведомление отклонено');
                    navigateToOriginalUrl([Routes.Notifications, Routes.Tasks]);
                });
            },
            () => {
                setErrorAlertText('Ошибка при отклонении уведомления');
            },
        );
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.makeRiskDecision}>
                <div className={'container'}>
                    <h2>Принять решение</h2>
                    {notification ? (
                        <div>
                            <ViewNotificationComponent notification={notification} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onSearchRisk}>
                                        Поиск существующего риска в БД
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCreateRisk}>
                                        Создать риск на основе уведомления
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => setRejectModalVisible(true)}>
                                        Отклонить уведомление
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>

                            <RejectNotificationModal
                                notificationId={notification.id}
                                isVisible={rejectModalVisible}
                                setIsVisible={setRejectModalVisible}
                                onSubmit={onSubmitRejectForm}
                            />
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={onReject}
                            />
                        </div>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { MakeRiskDecision };

import { Box, Button } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { UIModal } from 'components/UI/UIModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { MULTILINE_INPUT_MAX_LENGTH } from 'core/constants/common';
import { notificationsService } from 'core/services/notificationsService';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { Routes } from 'navigation/routes';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    notificationId: number;
    onSubmit?: (text: string) => void;
};

const RejectNotificationModal: FC<Props> = ({ isVisible, setIsVisible, notificationId }) => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const {
        control,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { rejectionText: '' },
        shouldUseNativeValidation: false,
    });

    const onSubmit = (): void => {
        const { rejectionText } = getValues();

        if (!isValid || rejectionText.length < 5) {
            setErrorAlertText(
                `Текст причины должен быть не меньше 5 и не больше ${MULTILINE_INPUT_MAX_LENGTH} символов`,
            );
        } else {
            onReject(rejectionText);
        }
    };

    const onReject = (reason: string) => {
        notificationsService.rejectNotification(
            notificationId,
            reason,
            () => {
                setSuccessAlertText('Уведомление отклонено');
                navigateToOriginalUrl([Routes.Notifications, Routes.Tasks]);
            },
            () => {
                setErrorAlertText('Ошибка при отклонении уведомления');
            },
        );
    };

    return (
        <UIModal
            isOpen={isVisible}
            title={'Укажите причину отклонения уведомления'}
            onClose={() => setIsVisible(false)}
        >
            <>
                <ControlledTextInput
                    name={'rejectionText'}
                    control={control}
                    label={'Введите текст'}
                    error={!!errors.rejectionText}
                    required
                    multiline
                />
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box mr={'10px'}>
                        <Button variant="contained" type={'submit'} onClick={onSubmit}>
                            Отклонить уведомление
                        </Button>
                    </Box>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={() => setIsVisible(false)}>
                            Назад
                        </Button>
                    </Box>
                </Box>
            </>
        </UIModal>
    );
};

export { RejectNotificationModal };

import { FC, useState } from 'react';
import * as React from 'react';
import { Button, Stack, Paper, Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { Control } from 'react-hook-form';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { FilterDateFields } from 'components/common/FilterDateFields';
import styles from './RisksFilter.module.scss';

type Props = {
    onSubmit: () => void;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (date: Dayjs | null) => void;
    setDateTo: (date: Dayjs | null) => void;
    onChangeFilter: (key: string, value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
    onReset: () => void;
};

const RisksFilter: FC<Props> = ({
    onSubmit,
    dateFrom,
    setDateFrom,
    onChangeFilter,
    dateTo,
    setDateTo,
    onReset,
    control,
}) => {
    const {
        dictionaryRiskStatusesInputOptions,
        sourceOfRiskDataInputOptions,
        dictionaryClassesInputOptions,
        dictionaryLifecycleStagesInputOptions,
        dictionaryStaffingTableInputOptions,
        dictionaryRiskObjectsInputOptions,
    } = useAdditionalData();

    const [showAllFields, setShowAllFields] = useState<boolean>(false);

    return (
        <div>
            <FilterDateFields
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                dateFrom={dateFrom}
                setShowAllFields={setShowAllFields}
                showAllFields={showAllFields}
            />

            {showAllFields && (
                <Paper elevation={3} className={styles.allFieldsContainer}>
                    <div className={styles.allFields}>
                        <Stack spacing={2}>
                            <ControlledTextInput
                                name={'shortName'}
                                control={control}
                                label={'Краткое наименование риска'}
                                onChange={(value) => onChangeFilter('shortName', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Источник возникновения'}
                                name={'sourceOfOrigin'}
                                options={sourceOfRiskDataInputOptions}
                                onChange={(value) => onChangeFilter('sourceOfOrigin', value.toString())}
                            />
                            <ControlledTextInput
                                name={'riskRequirements'}
                                control={control}
                                label={'Источник риска'}
                                onChange={(value) => onChangeFilter('riskRequirements', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Классификация риска'}
                                name={'riskClassification'}
                                options={dictionaryClassesInputOptions}
                                onChange={(value) => onChangeFilter('riskClassification', value.toString())}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Объект воздействия'}
                                name={'objectOfInfluence'}
                                options={dictionaryRiskObjectsInputOptions}
                                onChange={(value) => onChangeFilter('objectOfInfluence', value.toString())}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Этап ЖЦИ'}
                                name={'lifeCycleStage'}
                                options={dictionaryLifecycleStagesInputOptions}
                                onChange={(value) => onChangeFilter('lifeCycleStage', value.toString())}
                            />
                            <Box mr={'10px'}>
                                <Button variant="outlined" onClick={onSubmit}>
                                    Искать
                                </Button>
                                <Button variant="text" onClick={onReset}>
                                    Очистить поиск
                                </Button>
                            </Box>
                        </Stack>

                        <Stack spacing={2}>
                            {/*<ControlledSelectInput*/}
                            {/*    control={control}*/}
                            {/*    label={'Система/подсистема/узел'}*/}
                            {/*    name={'systemSubsystemNode'}*/}
                            {/*    options={dictionaryRiskSystemInputOptions}*/}
                            {/*    onChange={(value) => onChangeFilter('systemSubsystemNode', value.toString())}*/}
                            {/*/>*/}
                            <ControlledSelectInput
                                control={control}
                                label={'Штатное расписание'}
                                name={'staffingSchedule'}
                                options={dictionaryStaffingTableInputOptions}
                                onChange={(value) => onChangeFilter('staffingSchedule', value.toString())}
                            />
                            <ControlledTextInput
                                name={'reason'}
                                control={control}
                                label={'Причина риска'}
                                onChange={(value) => onChangeFilter('reason', value)}
                            />
                            <ControlledTextInput
                                name={'riskImpactDescription'}
                                control={control}
                                label={'Описание воздействия риска'}
                                onChange={(value) => onChangeFilter('riskImpactDescription', value)}
                            />
                            <ControlledTextInput
                                name={'createdBy'}
                                control={control}
                                label={'Кем создано'}
                                onChange={(value) => onChangeFilter('createdBy', value)}
                            />
                            <ControlledSelectInput
                                control={control}
                                label={'Статус'}
                                name={'status'}
                                options={dictionaryRiskStatusesInputOptions}
                                onChange={(value) => onChangeFilter('status', value.toString())}
                            />
                        </Stack>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export { RisksFilter };

import { AppContainer } from 'components/common/appContainer';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { useAppStore } from 'core/store/app/useAppStore';
import { RiskFormDefaultValues } from 'core/constants/forms/RiskFormDefaultValues';
import { risksService } from 'core/services/risksService';
import { RiskType } from 'types/risks/RiskType';
import { fillRiskFormDefaultValues } from 'utils/fillRiskFormDefaultValues';
import { RiskFormType } from 'types/risks/RiskFormType';
import { axiosWithAuthHeader } from 'core/http';
import { RISKS, SEND_FOR_APPROVAL } from 'core/http/endpoints';
import { Routes } from 'navigation/routes';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from 'core/services/tasksService';
import { EditRiskComponent } from '../components/EditRiskComponent';
import { RiskStatus } from '../../../core/constants/RiskStatus';

const SendRiskToApprove = () => {
    const { riskId } = useParams();
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [risk, setRisk] = useState<RiskType>();
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: RiskFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (riskId) {
            getRisk(riskId);
        }
    }, []);

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onSendForApproval = (dueDate: string): void => {
        if (!risk) return;

        axiosWithAuthHeader
            .patch(`${RISKS}/${riskId as string}${SEND_FOR_APPROVAL}`)
            .then((res: { data: { taskId: number } }) => {
                const { riskStatus } = risk;

                const onSuccess = () => {
                    setSuccessAlertText('Риск отправлен на согласование.');
                    navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
                };

                if (riskStatus.sysName === RiskStatus.UnderRevision) {
                    onSuccess();
                } else {
                    const { taskId } = res.data;
                    tasksService.setDueDate(dueDate, taskId, onSuccess);
                }
            })
            .catch(() => {
                setErrorAlertText('Ошибка при отправке риска на согласование.');
            });
    };

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            fillRiskFormDefaultValues(risk, setValue);
            setRisk(risk);
        });
    };

    const onEditRisk: SubmitHandler<RiskFormType> = (data) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .put(`${RISKS}/${riskId}`, {
                shortName: data.name,
                riskRequirements: data.riskRequirements,
                riskClassId: data.riskClassification,
                riskObjectId: data.objectOfInfluence,
                lifecycleStageId: data.lifeCycleStage,
                riskSystemId: data.systemSubsystemNode,
                staffingTableId: data.staffingSchedule,
                reason: data.reason,
                riskImpactDescription: data.description,
                comment: data.comment,
            })
            .then(() => {
                setSuccessAlertText('Риск успешно отредактирован');
            })
            .catch(() => {
                setErrorAlertText('Ошибка при редактировании риска');
            });
    };

    const onCancel = () => {
        navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.sendOnApproval}>
                <form className={'container_thin'} noValidate onSubmit={handleSubmit(onEditRisk)}>
                    <h2>Согласование риска</h2>
                    <EditRiskComponent errors={errors} control={control} />
                    {risk?.revisionReason && (
                        <Stack spacing={2} mb={3}>
                            <div>Причина возврата на доработку:</div>
                            <div>{risk?.revisionReason}</div>
                        </Stack>
                    )}
                    <Box sx={{ display: 'flex', marginBottom: 4 }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" onClick={() => setDeadlineRejectModalVisible(true)}>
                                Отправить на согласование
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" type={'submit'} onClick={onSave}>
                                Сохранить изменения
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={onCancel}>
                                Отменить
                            </Button>
                        </Box>
                    </Box>
                </form>
                {risk && (
                    <TaskDeadlineModal
                        isVisible={deadlineModalVisible}
                        setIsVisible={setDeadlineRejectModalVisible}
                        onSuccess={onSendForApproval}
                    />
                )}
            </UserRightsContainer>
        </AppContainer>
    );
};

export { SendRiskToApprove };

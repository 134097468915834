import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { useRisk } from 'hooks/useRisk';
import { UILoader } from 'components/UI/UILoader';
import { AppContainer } from 'components/common/appContainer';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { RiskAssessmentFormDefaultValues } from 'core/constants/forms/RiskAssessmentFormDefaultValues';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { UserRoles } from 'core/constants/UserRoles';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { risksService } from 'core/services/risksService';
import { Routes } from 'navigation/routes';
import { useUsers } from 'hooks/useUsers';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from 'core/services/tasksService';
import { AssessRiskForm } from '../components/AssessRiskForm';

const ChangeRiskOwner = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const { risk } = useRisk(riskId);
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const { users } = useUsers(UserRoles.risk_owner);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    const {
        watch,
        control,
        getValues,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            ...RiskAssessmentFormDefaultValues,
            riskOwner: { label: '', value: '' },
            assessmentRevisionComment: '',
        },
        shouldUseNativeValidation: false,
    });

    const watchFields = watch(['expectedScheduleChange', 'riskProbability', 'expectedDamageCost']);

    useEffect(() => {
        if (risk) {
            setValue('rank', risk.rank.toString());
            setValue('minimalScheduleChange', risk.minimalScheduleChange.toString());
            setValue('expectedScheduleChange', risk.expectedScheduleChange.toString());
            setValue('maximumScheduleChange', risk.maximumScheduleChange.toString());
            setValue('minimalDamageCost', risk.minimalDamageCost.toString());
            setValue('expectedDamageCost', risk.expectedDamageCost.toString());
            setValue('maximumDamageCost', risk.maximumDamageCost.toString());
            setValue('riskProbability', risk.riskProbability.toString());
            setValue('riskAcceptanceCriterion', risk.riskAcceptanceCriterion.toString());
            setValue('riskAssessmentMethodId', risk.riskAssessmentMethod.id.toString());
            setValue('riskOwner', { value: risk.riskOwner.id.toString(), label: userFullNameFromUser(risk.riskOwner) });
        }
    }, [risk]);

    const onSubmit = (dueDate: string) => {
        if (!riskId) return;

        const data = getValues();
        risksService.changeRiskOwner(
            riskId,
            { ...data, riskOwnerId: data.riskOwner.value, riskAssessmentId: data.riskAssessmentMethodId },
            (res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Владелец риска успешно изменен');
                    navigate(Routes.Risks);
                });
            },
            () => {
                setErrorAlertText('Ошибка при изменении владельца риска');
            },
        );
    };

    const onChangeRiskOwner = () => {
        if (isValid) {
            setDeadlineRejectModalVisible(true);
        } else {
            setErrorAlertText('Заполните все обязательные поля');
        }
    };

    const riskOwners = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.changeRiskOwner}>
                <div className={'container_thin'}>
                    <h2>Оценка риска</h2>
                    {risk ? (
                        <form noValidate>
                            <ViewRiskComponent risk={risk} />
                            <AssessRiskForm
                                control={control}
                                errors={errors}
                                riskProbability={watchFields[1]}
                                expectedScheduleChange={watchFields[0]}
                                expectedDamageCost={watchFields[2]}
                            />
                            <div className={'fields-container'}>
                                <span>Владелец риска:</span>
                                <ControlledAutocompleteInput
                                    control={control}
                                    label={'Владелец риска'}
                                    name={'riskOwner'}
                                    error={!!errors.riskOwner}
                                    options={riskOwners}
                                    required
                                />
                                <span>Комментарий для уточнения оценки риска:</span>
                                <ControlledTextInput
                                    name={'assessmentRevisionComment'}
                                    control={control}
                                    label={'Комментарий'}
                                    error={!!errors.assessmentRevisionComment}
                                    multiline
                                    required
                                />
                            </div>
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" onClick={onChangeRiskOwner}>
                                        Сменить владельца риска
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => navigate(-1)}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={onSubmit}
                            />
                        </form>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ChangeRiskOwner };

import { FC } from 'react';
import * as React from 'react';
import { Link, Stack } from '@mui/material';

import { NotificationType } from 'types/notifications/NotificationType';
import { NotificationStatus } from 'core/constants/NotificationStatus';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { Routes } from 'navigation/routes';

type Props = {
    notification: NotificationType;
    showAll?: boolean;
};

const ViewNotificationComponent: FC<Props> = ({ notification, showAll }) => {
    const showRisks =
        notification.status.sysName === NotificationStatus.Approved ||
        notification.status.sysName === NotificationStatus.Rejected;

    return (
        <div>
            <div className="fields-container">
                <span>Наименование уведомления:</span>
                <span>{notification?.notificationName}</span>
                <span>Причина риска:</span>
                <span>{notification?.riskReason}</span>
                <span>Объект воздействия:</span>
                <span>{notification?.riskObject.name}</span>
                <span>Приоритет:</span>
                <span>{notification?.priority?.name}</span>
                <span>Источник риска:</span>
                <span>{notification?.riskRequirements}</span>
                <span>Время обнаружения:</span>
                <span>{notification?.detectionDate}</span>
                <span>Описание воздействия:</span>
                <span>{notification?.impactDescription}</span>
                <span>Местоположение риска:</span>
                <span>{notification?.riskLocation}</span>
                <span>Сроки реагирования:</span>
                <span>
                    {notification?.responseTime} {notification?.responseUnitName?.toLowerCase()}
                </span>
                <span>Комментарий:</span>
                <span>{notification?.comment || '-'}</span>
                {showAll && (
                    <>
                        <span>Кем создано:</span>
                        <span>{userFullNameFromUser(notification?.sender)}</span>
                        <span>Когда создано:</span>
                        <span>{notification?.createDate}</span>
                    </>
                )}
                {notification.status.sysName !== NotificationStatus.Draft && (
                    <>
                        <span>Согласующий сотрудник:</span>
                        <span>{userFullNameFromUser(notification.coordinatingUser)}</span>
                        <span>Утверждающий сотрудник:</span>
                        <span>{userFullNameFromUser(notification.approvingUser)}</span>
                        <span>Комментарий для согласования:</span>
                        <span>{notification?.approvalComment || '-'}</span>
                    </>
                )}
                <span>Статус:</span>
                <span>{notification?.status?.name}</span>
                {notification.status.sysName === NotificationStatus.Rejected && (
                    <>
                        <span>Комментарий для отклонения:</span>
                        <span>{notification?.rejectionComment}</span>
                    </>
                )}
                {notification?.riskSpecialist && (
                    <>
                        <span>Специалист по рискам:</span>
                        <span>{userFullNameFromUser(notification.riskSpecialist)}</span>
                    </>
                )}
            </div>
            {showAll && showRisks && (
                <>
                    {notification.risks.length === 0 ? (
                        <h4>Рисков не создано</h4>
                    ) : (
                        <Stack spacing={1} mb={3}>
                            <h3>Созданные риски</h3>
                            <ol>
                                {notification.risks.map((risk) => (
                                    <li key={risk.id}>
                                        <Link href={`${Routes.ViewRisk}/${risk.id}`} target={'_blank'}>
                                            {risk.shortName}
                                        </Link>
                                    </li>
                                ))}
                            </ol>
                        </Stack>
                    )}
                </>
            )}
        </div>
    );
};

export { ViewNotificationComponent };

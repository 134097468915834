import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Routes } from 'navigation/routes';

import { AppContainer } from 'components/common/appContainer';
import { useRisk } from 'hooks/useRisk';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { UILoader } from 'components/UI/UILoader';
import { RiskAssessmentFormDefaultValues } from 'core/constants/forms/RiskAssessmentFormDefaultValues';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { useAppStore } from 'core/store/app/useAppStore';
import { risksService } from 'core/services/risksService';
import { fillRiskFormDefaultValues } from 'utils/fillRiskAssessFromDefaultValues';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from 'core/services/tasksService';
import { AssessRiskForm } from '../components/AssessRiskForm';

const SendRiskAssessmentForRevision = () => {
    const navigate = useNavigate();
    const { riskId } = useParams();

    const { risk } = useRisk(riskId);

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    const {
        watch,
        control,
        getValues,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: RiskAssessmentFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    const watchFields = watch(['expectedScheduleChange', 'riskProbability', 'expectedDamageCost']);

    useEffect(() => {
        if (risk) {
            fillRiskFormDefaultValues(risk, setValue);
            if (risk.assessmentRevisionComment) {
                setValue('assessmentRevisionComment', risk.assessmentRevisionComment);
            }
        }
    }, [risk]);

    const onSubmit = (dueDate: string) => {
        if (!riskId) return;

        const data = getValues();
        risksService.sendAssessmentForRevision(
            riskId,
            { ...data, riskAssessmentId: data.riskAssessmentMethodId },
            (res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Оценка риска успешно отправлена на доработку');
                    navigate(Routes.Risks);
                });
            },
            () => {
                setErrorAlertText('Ошибка при отправке оценки риска на доработку');
            },
        );
    };

    const onSendForRevision = () => {
        if (isValid) {
            setDeadlineRejectModalVisible(true);
        } else {
            setErrorAlertText('Заполните все обязательные поля');
            return;
        }
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.sendUnderRevision}>
                <div className={'container_thin'}>
                    <h2>Оценка риска</h2>
                    {risk ? (
                        <form noValidate>
                            <ViewRiskComponent risk={risk} />
                            <AssessRiskForm
                                control={control}
                                errors={errors}
                                riskProbability={watchFields[1]}
                                expectedScheduleChange={watchFields[0]}
                                expectedDamageCost={watchFields[2]}
                            />
                            <div className={'fields-container'}>
                                <span>Комментарий для уточнения оценки риска:</span>
                                <ControlledTextInput
                                    name={'assessmentRevisionComment'}
                                    control={control}
                                    label={'Комментарий'}
                                    error={!!errors.assessmentRevisionComment}
                                    multiline
                                    required
                                />
                            </div>
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" onClick={onSendForRevision}>
                                        Отправить на доработку
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={() => navigate(-1)}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={onSubmit}
                            />
                        </form>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { SendRiskAssessmentForRevision };

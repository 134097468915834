import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { EventFormType } from 'types/events/EventFormType';
import { axiosWithAuthHeader } from 'core/http';
import { EVENTS, REPETITIVE_EVENTS } from 'core/http/endpoints';
import { useAppStore } from 'core/store/app/useAppStore';
import { EventType } from 'types/events/EventType';
import { EventStatus } from '../core/constants/EventStatus';
import { userRolesPermissions } from '../core/constants/userRolesPermissions';

const useEditEvent = ({ riskId, eventId }: { riskId?: string; eventId?: string }) => {
    const navigate = useNavigate();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [event, setEvent] = useState<EventType>();

    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = () => {
        if (!riskId || !eventId) return;

        axiosWithAuthHeader
            .get(`/${riskId}${EVENTS}/${eventId}`)
            .then((res) => {
                setEvent(res.data as EventType);
            })
            .catch((error) => {
                console.log(EVENTS, error);
            });
    };

    const editEvent = (data: EventFormType, expandForm: boolean): void => {
        if (!riskId || !eventId) return;

        const additionalFields = {
            dueDate: data.dueDate?.format('YYYY-MM-DD HH:mm:ss'),
            controlDate: data.controlDate?.format('YYYY-MM-DD HH:mm:ss'),
            responsibleId: data.eventResponsible?.value,
        };

        axiosWithAuthHeader
            .put(`/${riskId}${EVENTS}/${eventId}`, {
                eventText: data.eventText,
                eventTypeId: data.eventTypeId,
                riskSystemId: data.systemSubsystemNode,
                ...(expandForm && { ...additionalFields }),
            })
            .then(() => {
                editRepetitiveEvent(data);
            })
            .catch(() => {
                setErrorAlertText('Ошибка при редактировании мероприятия.');
            });
    };

    const editRepetitiveEvent = (data: EventFormType) => {
        if (!eventId || !event) return;

        const params = {
            repeatUnit: data.repeatUnit,
            isCanceled: false,
            repeatDay: data.repeatDayOfMonth,
            repeatMonth: data.repeatMonth,
            repeatDayOfWeek: data.repeatDayOfWeek,
            selectedDates: data.selectedDates.split(', ').filter((date) => !!date),
        };

        if (data.repeatEvent && !event.repetitiveEvent) {
            axiosWithAuthHeader
                .post(`${REPETITIVE_EVENTS}${eventId}`, params)
                .then(onFormSuccess)
                .catch(() => {
                    setErrorAlertText('Ошибка при редактировании мероприятия.');
                });
        } else if (data.repeatEvent && event.repetitiveEvent) {
            axiosWithAuthHeader
                .put(`${REPETITIVE_EVENTS}${event.id}/${event.repetitiveEvent.id}`, params)
                .then(onFormSuccess)
                .catch(() => {
                    setErrorAlertText('Ошибка при редактировании мероприятия.');
                });
        } else if (!data.repeatEvent && event.repetitiveEvent) {
            axiosWithAuthHeader
                .patch(`${REPETITIVE_EVENTS}${event.repetitiveEvent.id}/remove`)
                .then(onFormSuccess)
                .catch(() => {
                    setErrorAlertText('Ошибка при редактировании мероприятия.');
                });
        } else {
            onFormSuccess();
        }
    };

    const onFormSuccess = () => {
        setSuccessAlertText('Мероприятие успешно отредактировано.');
        navigate(-1);
    };

    const permittedUserRolesToEdit = useMemo(() => {
        if (!event) return [];

        return (
            {
                [EventStatus.Draft]: userRolesPermissions.events.editFromDraft,
                [EventStatus.WaitsApproval]: userRolesPermissions.events.editFromWaitsApproval,
                [EventStatus.UnderRevision]: userRolesPermissions.events.editFromUnderRevision,
                [EventStatus.ResponsibleAppointed]: userRolesPermissions.events.editFromResponsibleAppointed,
                [EventStatus.UnderRevisionByResponsible]:
                    userRolesPermissions.events.editFromUnderRevisionByResponsible,
                [EventStatus.ApprovedByProjectManager]: userRolesPermissions.events.editFromApprovedByProjectManager,
            }[event.eventStatus.sysName] || []
        );
    }, [event]);

    return {
        editEvent,
        event,
        editRepetitiveEvent,
        permittedUserRolesToEdit,
    };
};

export { useEditEvent };

import { AxiosResponse } from 'axios';

import { axiosWithAuthHeader } from '../http';
import { TASK_SET_DUE_DATE, TASKS } from '../http/endpoints';

// eslint-disable-next-line
type CallbackType = (res: AxiosResponse<any>) => void;

export const tasksService = {
    setDueDate: (dueDate: string, taskId: number, onSuccess: CallbackType) => {
        axiosWithAuthHeader
            .patch(`${TASKS}/${taskId}${TASK_SET_DUE_DATE}`, { dueDate })
            .then(onSuccess)
            .catch((error) => console.log('error', error));
    },
};

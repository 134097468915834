import { useEffect, useMemo, useState } from 'react';

import {
    APPROVE,
    APPROVE_EVENT_PLAN_WITH_DATES,
    EVENTS,
    SEND_FOR_APPROVAL,
    SEND_FOR_APPROVAL_BY_MANAGER,
} from 'core/http/endpoints';
import { axiosWithAuthHeader } from 'core/http';
import { EventType } from 'types/events/EventType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { useAppStore } from 'core/store/app/useAppStore';
import { PaginationType } from 'types/PaginationType';
import { tasksService } from '../core/services/tasksService';
import { renderRepetitiveEventInfo } from '../utils/renderRepetitiveEventInfo';

const useRiskEvents = (riskId?: string) => {
    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [events, setEvents] = useState<EventType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [paginationPage, setPaginationPage] = useState<number>(0);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    useEffect(() => {
        fetchEvents();
    }, [riskId, paginationPage]);

    const fetchEvents = () => {
        if (!riskId) return;

        setIsFetching(true);
        axiosWithAuthHeader
            .get<{ data: EventType[]; pagination: PaginationType }>(`${riskId}${EVENTS}`, {
                params: { page: paginationPage },
            })
            .then((res) => {
                setEvents(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const sendDraftOnApproval = (dueDate: string) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .patch(`${riskId}${EVENTS}${SEND_FOR_APPROVAL}`)
            .then((res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Мероприятия отправлены на согласование');
                    setDeadlineRejectModalVisible(false);
                    fetchEvents();
                });
            })
            .catch(() => setErrorAlertText('Ошибка при отправке мероприятий на согласование'));
    };

    const approveEvents = (dueDate: string) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .patch(`${riskId}${EVENTS}${APPROVE}`)
            .then((res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setDeadlineRejectModalVisible(false);
                    setSuccessAlertText('Мероприятия успешно согласованы');
                    fetchEvents();
                });
            })
            .catch(() => setErrorAlertText('Ошибка при согласовании меропрятий'));
    };

    const sendForApprovalByManager = (dueDate?: string) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .patch(
                `${riskId}${EVENTS}${SEND_FOR_APPROVAL_BY_MANAGER}`,
                events.map((event) => ({
                    eventId: event.id,
                    dueDate: event.dueDate,
                    controlDate: event.controlDate,
                    userId: event.responsible?.id,
                })),
            )
            .then((res: { data: { taskId: number } }) => {
                const { taskId } = res.data;

                const onSuccess = () => {
                    setDeadlineRejectModalVisible(false);
                    setSuccessAlertText('Мероприятия успешно отправлены на согласование руководителем');
                    fetchEvents();
                };

                if (dueDate) {
                    tasksService.setDueDate(dueDate, taskId, onSuccess);
                } else {
                    onSuccess();
                }
            })
            .catch(() => setErrorAlertText('Ошибка при отправке меропрятий на согласование'));
    };

    const approveEventPlanWithDates = (dueDate?: string) => {
        if (!riskId) return;

        axiosWithAuthHeader
            .patch(`${riskId}${EVENTS}${APPROVE_EVENT_PLAN_WITH_DATES}`)
            .then((res: { data: { taskId: number } }) => {
                const { taskId } = res.data;

                const onSuccess = () => {
                    setDeadlineRejectModalVisible(false);
                    setSuccessAlertText('Мероприятия успешно согласованы');
                    fetchEvents();
                };

                if (dueDate) {
                    tasksService.setDueDate(dueDate, taskId, onSuccess);
                } else {
                    onSuccess();
                }
            })
            .catch(() => setErrorAlertText('Ошибка при согласовании меропрятий'));
    };

    const tableRows = useMemo(() => {
        return events.map((event) => ({
            id: event.id,
            type: {
                eventName: event.eventType.name,
                repetitiveEventData: renderRepetitiveEventInfo(event),
            },
            text: event.eventText,
            responsible: event?.responsible ? userFullNameFromUser(event.responsible) : '',
            createdBy: userFullNameFromUser(event.createdBy),
            createDate: event.createDate,
            status: event.eventStatus.name,
            controlDate: event.controlDate,
            systemSubsystemNode: event?.riskSystem?.name,
            dueDate: event.dueDate,
        }));
    }, [events]);

    return {
        events,
        tableRows,
        isFetching,
        totalCount,
        approveEvents,
        setPaginationPage,
        sendDraftOnApproval,
        sendForApprovalByManager,
        approveEventPlanWithDates,
        deadlineModalVisible,
        setDeadlineRejectModalVisible,
    };
};

export { useRiskEvents };

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { UITable } from 'components/UI/UITable';
import { useTasksPage } from 'hooks/useTasksPage';
import { TasksTableColumns } from 'core/constants/tables/TasksTableColumns';
import { TasksFilter } from '../TasksFilter';

export const OverdueTasks = () => {
    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    const {
        isFetching,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        setPaginationPage,
        fetchOverDueTasks,
        totalCount,
        overDueTasks,
        tableRowsOverdueTasks,
    } = useTasksPage();

    const selectedTask = useMemo(() => {
        return overDueTasks.find((n) => n.id === selectedRows[0]);
    }, [selectedRows[0]]);

    const extendTaskDeadline = () => {
        setDeadlineRejectModalVisible(true);
    };

    return (
        <div>
            <h3>Просроченные задачи</h3>

            <TasksFilter dateFrom={dateFrom} setDateTo={setDateTo} dateTo={dateTo} setDateFrom={setDateFrom} />

            <Box mr={'10px'}>
                <Button variant="outlined" disabled={!selectedTask} onClick={extendTaskDeadline}>
                    Продлить срок выполнения задачи
                </Button>
            </Box>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет задач'}
                rows={tableRowsOverdueTasks}
                columns={TasksTableColumns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
                onChangePaginationPage={setPaginationPage}
            />

            <TaskDeadlineModal
                taskId={selectedTask?.id || 0}
                setIsVisible={setDeadlineRejectModalVisible}
                isVisible={deadlineModalVisible}
                showAutoButton={false}
                onSuccess={() => {
                    setDeadlineRejectModalVisible(false);
                    fetchOverDueTasks();
                }}
            />
        </div>
    );
};

import { FC } from 'react';
import * as React from 'react';

import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { RiskType } from 'types/risks/RiskType';
import { RiskStatus } from '../../../core/constants/RiskStatus';
import { checkStatusIn } from '../../../utils/checkStatusUtils';

type Props = {
    risk: RiskType;
    showAll?: boolean;
};

const ViewRiskComponent: FC<Props> = ({ risk, showAll }) => {
    return (
        <>
            <div className="fields-container">
                <span>Краткое наименование риска:</span>
                <span>{risk?.shortName}</span>
                <span>Источник возникновения:</span>
                <span>{risk?.sourceOfRiskData?.name}</span>
                <span>Источник риска:</span>
                <span>{risk?.riskRequirements}</span>
            </div>
            <h3>Идентификация риска</h3>
            <div className="fields-container">
                <span>Классификация риска:</span>
                <span>{risk?.riskClass?.name}</span>
                <span>Объект воздействия:</span>
                <span>{risk?.riskObject?.name}</span>
                <span>Этап ЖЦИ:</span>
                <span>{risk?.lifecycleStage?.name}</span>
                <span>Система/подсистема/узел:</span>
                <span>{risk?.riskSystem?.name}</span>
                <span>Штатное расписание:</span>
                <span>{risk?.staffingTable?.name}</span>
                <span>Причина риска:</span>
                <span>{risk?.reason}</span>
                <span>Описание воздействия риска:</span>
                <span>{risk?.riskImpactDescription}</span>
                <span>Комментарий:</span>
                <span>{risk.comment}</span>
                {showAll && (
                    <>
                        {checkStatusIn(risk.riskStatus.sysName, [
                            RiskStatus.RiskOwnerAppointed,
                            RiskStatus.WaitsAssessment,
                            RiskStatus.Assessed,
                            RiskStatus.AssessmentApproved,
                            RiskStatus.ReturnedToProjectManager,
                            RiskStatus.ApprovedByCoordinator,
                        ]) && (
                            <>
                                {!checkStatusIn(risk.riskStatus.sysName, [RiskStatus.RiskOwnerAppointed]) && (
                                    <>
                                        <span>Метод оценки риска:</span>
                                        <span>{risk.riskAssessmentMethod?.name}</span>
                                        <span>Изменение сроков минимальное:</span>
                                        <span>{risk.minimalScheduleChange}</span>
                                        <span>Изменение сроков ожидаемое:</span>
                                        <span>{risk.expectedScheduleChange}</span>
                                        <span>Изменение сроков максимальное:</span>
                                        <span>{risk.maximumScheduleChange}</span>
                                        <span>Стоимость ущерба минимальная:</span>
                                        <span>{risk.minimalDamageCost}</span>
                                        <span>Стоимость ущерба ожидаемая:</span>
                                        <span>{risk.expectedDamageCost}</span>
                                        <span>Стоимость ущерба максимальная:</span>
                                        <span>{risk.maximumDamageCost}</span>
                                        <span>Вероятность риска:</span>
                                        <span>{risk.riskProbability}%</span>
                                        <span>Критерий приемлемости риска:</span>
                                        <span>{risk.riskAcceptanceCriterion}</span>
                                        <strong>Результат количественной оценки (сроки):</strong>
                                        <span>{risk.riskAssessmentResultTime}</span>
                                        <strong>Результат количественной оценки (стоимость):</strong>
                                        <span>{risk.riskAssessmentResultMoney}</span>
                                        <strong>Ранг риска в порядке возрастания:</strong>
                                        <span>{risk.rank}</span>
                                    </>
                                )}
                                <span>Владелец риска:</span>
                                <span>{risk.riskOwner && userFullNameFromUser(risk.riskOwner)}</span>
                            </>
                        )}
                        <span>Кем создано:</span>
                        <span>{risk.createdBy && userFullNameFromUser(risk.createdBy)}</span>
                        <span>Когда создано:</span>
                        <span>{risk?.createDate}</span>
                    </>
                )}
                <span>Статус:</span>
                <span>{risk.riskStatus?.name}</span>
                {risk.rejectionReason && (
                    <>
                        <span>Причина отклонения риска:</span>
                        <span>{risk.rejectionReason}</span>
                    </>
                )}
                {risk.revisionReason && (
                    <>
                        <span>Причина отправки на доработку:</span>
                        <span>{risk.revisionReason}</span>
                    </>
                )}
            </div>
        </>
    );
};

export { ViewRiskComponent };

import * as React from 'react';
import { Control } from 'react-hook-form';
import { FC } from 'react';

import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { daysOfMonth, daysOfWeek, months, repeatEventUnits } from '../../CreateEvent/constants';
import { CustomRepeatCalendarDayField } from '../CustomRepeatCalendarDayField/CustomRepeatCalendarDayField';

type Props = {
    // eslint-disable-next-line
    control: Control<any>;
    defaultValue: string;
    setValue: (fieldName: 'selectedDates', value: string) => void;
    eventRepeatUnit: string;
    repeatEvent: boolean;
};

export const RepetitiveEventForm: FC<Props> = ({ control, setValue, repeatEvent, eventRepeatUnit, defaultValue }) => {
    return (
        <>
            <ControlledSelectInput
                control={control}
                label={'Повторять'}
                name={'repeatUnit'}
                options={repeatEventUnits}
                required={repeatEvent}
            />
            {eventRepeatUnit === 'WEEK' && (
                <ControlledSelectInput
                    control={control}
                    label={'День недели'}
                    name={'repeatDayOfWeek'}
                    options={daysOfWeek}
                    required={repeatEvent && eventRepeatUnit === 'WEEK'}
                />
            )}
            {(eventRepeatUnit === 'MONTH' || eventRepeatUnit === 'YEAR') && (
                <ControlledSelectInput
                    control={control}
                    label={'Число месяца'}
                    name={'repeatDayOfMonth'}
                    options={daysOfMonth}
                    required={repeatEvent && eventRepeatUnit === 'MONTH'}
                />
            )}
            {eventRepeatUnit === 'YEAR' && (
                <ControlledSelectInput
                    control={control}
                    label={'Месяц'}
                    name={'repeatMonth'}
                    options={months}
                    required={repeatEvent && eventRepeatUnit === 'YEAR'}
                />
            )}
            {eventRepeatUnit === 'CUSTOM' && (
                <CustomRepeatCalendarDayField control={control} setValue={setValue} defaultValue={defaultValue} />
            )}
        </>
    );
};

import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

export const TasksTableColumns: GridColDef[] = [
    {
        field: 'name',
        flex: 20,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Задача</strong>,
    },
    {
        field: 'object',
        flex: 35,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Объект</strong>,
    },
    {
        field: 'status',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Текущий статус</strong>,
    },
    {
        field: 'createdBy',
        flex: 15,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Задачу поставил</strong>,
    },
    {
        field: 'createdAt',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Дата создания <br /> задачи
            </strong>
        ),
    },
    {
        field: 'dueDate',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Срок <br /> выполнения
            </strong>
        ),
    },
];

import { Box, Button } from '@mui/material';
import * as React from 'react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UIModal } from 'components/UI/UIModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { Routes } from 'navigation/routes';
import { risksService } from 'core/services/risksService';
import { MULTILINE_INPUT_MAX_LENGTH } from 'core/constants/common';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from '../../../../core/services/tasksService';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    riskId: number;
};

const ReturnRiskForRevisionModal: FC<Props> = ({ isVisible, setIsVisible, riskId }) => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    const {
        control,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { rejectionText: '' },
        shouldUseNativeValidation: false,
    });

    const onSubmit = (): void => {
        const { rejectionText } = getValues();

        if (!isValid || rejectionText.length < 5) {
            setErrorAlertText(`Текст должен быть не меньше 5 и не больше ${MULTILINE_INPUT_MAX_LENGTH} символов`);
        } else {
            setDeadlineRejectModalVisible(true);
        }
    };

    const onSendRiskForRevision = (dueDate: string) => {
        if (!riskId) return;
        const { rejectionText } = getValues();

        risksService.sendRiskForRevision(
            riskId,
            rejectionText,
            (res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Риск отправлен на доработку');
                    navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
                });
            },
            () => {
                setErrorAlertText('Ошибка при отправке риска на доработку');
            },
        );
    };

    const renderModalComponent = () => {
        return (
            <UIModal
                isOpen={isVisible}
                title={'Укажите, что необходимо доработать'}
                onClose={() => setIsVisible(false)}
            >
                <>
                    <ControlledTextInput
                        name={'rejectionText'}
                        control={control}
                        label={'Введите текст'}
                        error={!!errors.rejectionText}
                        required
                        multiline
                    />
                    <Box sx={{ display: 'flex', marginTop: '20px' }}>
                        <Box mr={'10px'}>
                            <Button variant="contained" type={'submit'} onClick={onSubmit}>
                                Вернуть на доработку
                            </Button>
                        </Box>
                        <Box mr={'10px'}>
                            <Button variant="outlined" onClick={() => setIsVisible(false)}>
                                Назад
                            </Button>
                        </Box>
                    </Box>
                </>
            </UIModal>
        );
    };

    const renderTaskDeadlineModal = () => (
        <TaskDeadlineModal
            isVisible={deadlineModalVisible}
            setIsVisible={setDeadlineRejectModalVisible}
            onSuccess={onSendRiskForRevision}
        />
    );

    return <div>{deadlineModalVisible ? renderTaskDeadlineModal() : renderModalComponent()}</div>;
};

export { ReturnRiskForRevisionModal };

import { Box, Button } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { UIModal } from 'components/UI/UIModal';
import { useAppStore } from 'core/store/app/useAppStore';
import { Routes } from 'navigation/routes';
import { risksService } from 'core/services/risksService';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { MULTILINE_INPUT_MAX_LENGTH } from 'core/constants/common';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    riskId: number;
};

const RejectRiskModal: FC<Props> = ({ isVisible, setIsVisible, riskId }) => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const {
        control,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { rejectionText: '' },
        shouldUseNativeValidation: false,
    });

    const onSubmit = (): void => {
        const { rejectionText } = getValues();

        if (!isValid || rejectionText.length < 5) {
            setErrorAlertText(
                `Текст причины должен быть не меньше 5 и не больше ${MULTILINE_INPUT_MAX_LENGTH} символов`,
            );
        } else {
            onReject();
        }
    };

    const onReject = () => {
        if (!riskId) return;
        const { rejectionText } = getValues();

        risksService.rejectRisk(
            riskId,
            rejectionText,
            () => {
                setSuccessAlertText('Риск отклонен');
                navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
            },
            () => {
                setErrorAlertText('Ошибка при отклонении риска');
            },
        );
    };

    return (
        <UIModal isOpen={isVisible} title={'Укажите причину отклонения риска'} onClose={() => setIsVisible(false)}>
            <>
                <ControlledTextInput
                    name={'rejectionText'}
                    control={control}
                    label={'Введите текст'}
                    error={!!errors.rejectionText}
                    required
                    multiline
                />
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box mr={'10px'}>
                        <Button variant="contained" type={'submit'} onClick={onSubmit}>
                            Отклонить риск
                        </Button>
                    </Box>
                    <Box mr={'10px'}>
                        <Button variant="outlined" onClick={() => setIsVisible(false)}>
                            Назад
                        </Button>
                    </Box>
                </Box>
            </>
        </UIModal>
    );
};

export { RejectRiskModal };

import { EventType } from '../types/events/EventType';
import { getMonthByNumber } from './getMonthByNumber';

export const renderRepetitiveEventInfo = (event: EventType): string => {
    if (event.repetitiveEvent) {
        const { repetitiveEvent } = event;
        switch (repetitiveEvent.responseUnit.name) {
            case 'WEEK': {
                return `${repetitiveEvent.repeatDayOfWeek.localizedName}, ${repetitiveEvent.responseUnit.localizedName}`;
            }
            case 'MONTH': {
                return `${repetitiveEvent.repeatDay} числа, ${repetitiveEvent.responseUnit.localizedName}`;
            }
            case 'YEAR': {
                return `${repetitiveEvent.repeatDay} ${getMonthByNumber(repetitiveEvent.repeatMonth)}, ${repetitiveEvent.responseUnit.localizedName}`;
            }
            case 'CUSTOM': {
                return repetitiveEvent.selectedDates.join(', ');
            }
            default: {
                return '';
            }
        }
    } else {
        return '';
    }
};

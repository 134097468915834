import { AppContainer } from 'components/common/appContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useAppStore } from 'core/store/app/useAppStore';
import { risksService } from 'core/services/risksService';
import { RiskType } from 'types/risks/RiskType';
import { axiosWithAuthHeader } from 'core/http';
import { APPROVE, RISKS } from 'core/http/endpoints';
import { Routes } from 'navigation/routes';
import { ViewRiskComponent } from 'components/common/ViewRiskComponent';
import { UILoader } from 'components/UI/UILoader';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from 'core/services/tasksService';
import { ReturnRiskForRevisionModal } from '../components/ReturnRiskForRevisionModal';
import { RejectRiskModal } from '../components/RejectRiskModal';

const ApproveRisk = () => {
    const navigate = useNavigate();
    const { riskId } = useParams<{ riskId: string }>();
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);
    const [revisionModalVisible, setRevisionModalVisible] = useState<boolean>(false);
    const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
    const [risk, setRisk] = useState<RiskType>();
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (riskId) {
            getRisk(riskId);
        }
    }, []);

    const onApprove = (dueDate: string): void => {
        axiosWithAuthHeader
            .patch(`${RISKS}/${riskId as string}${APPROVE}`)
            .then((res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Риск успешно согласован.');
                    navigate(`${Routes.AddRiskOwner}/${riskId as string}`);
                });
            })
            .catch(() => {
                setErrorAlertText('Ошибка при согласовании риска.');
            });
    };

    const getRisk = (id: string) => {
        risksService.getRisk(id, (res) => {
            const risk = res.data as RiskType;
            setRisk(risk);
        });
    };

    const onEditRisk = (): void => {
        if (!riskId) return;
        navigate(`${Routes.EditRisk}/${riskId}`);
    };

    const onCancel = () => {
        navigateToOriginalUrl([Routes.Risks, Routes.Tasks]);
    };

    const onSubmitApprove = () => {
        setDeadlineRejectModalVisible(true);
    };

    const onSubmitReturnForRevision = () => {
        setRevisionModalVisible(true);
    };

    const onSubmitReject = () => {
        setRejectModalVisible(true);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.risks.approve}>
                <div className={'container_thin'}>
                    <h2>Принять решение</h2>
                    {risk ? (
                        <>
                            <ViewRiskComponent risk={risk} />
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" onClick={onSubmitApprove}>
                                        Согласовать
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" type={'submit'} onClick={onSubmitReturnForRevision}>
                                        Вернуть на доработку
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" type={'submit'} onClick={onSubmitReject}>
                                        Отклонить
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" type={'submit'} onClick={onEditRisk}>
                                        Редактировать
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                            <ReturnRiskForRevisionModal
                                riskId={risk.id}
                                isVisible={revisionModalVisible}
                                setIsVisible={setRevisionModalVisible}
                            />
                            <RejectRiskModal
                                riskId={risk.id}
                                isVisible={rejectModalVisible}
                                setIsVisible={setRejectModalVisible}
                            />
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={onApprove}
                            />
                        </>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { ApproveRisk };

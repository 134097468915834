import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useMemo } from 'react';
import { GridCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import dayjs from 'dayjs';

import { UITable } from 'components/UI/UITable';
import { TasksTableColumns } from 'core/constants/tables/TasksTableColumns';
import { useTasksPage } from 'hooks/useTasksPage';
import { TasksFilter } from '../TasksFilter';
import styles from '../../Tasks.module.scss';

export const MyTasks = () => {
    const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

    const {
        tableRows,
        isFetching,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        setPaginationPage,
        totalCount,
        tasks,
        accomplishTask,
    } = useTasksPage();

    const getCellClassName = (params: GridCellParams<GridValidRowModel, string, GridValidRowModel>) => {
        let style = '';

        if (params.field === 'dueDate') {
            if (dayjs(params.value, 'DD-MM-YYYY').add(-1, 'day') < dayjs()) {
                style = styles.orange;
            }
            if (dayjs(params.value, 'DD-MM-YYYY') < dayjs().add(-1, 'day')) {
                style = styles.red;
            }
        }
        return style;
    };

    const selectedTask = useMemo(() => {
        return tasks.find((n) => n.id === selectedRows[0]);
    }, [selectedRows[0]]);

    return (
        <div>
            <TasksFilter dateFrom={dateFrom} setDateTo={setDateTo} dateTo={dateTo} setDateFrom={setDateFrom} />

            <Box mr={'10px'}>
                <Button variant="outlined" disabled={!selectedTask} onClick={() => accomplishTask(selectedTask)}>
                    Выполнить задачу
                </Button>
            </Box>

            <UITable
                totalCount={totalCount}
                noRowsText={'Нет задач'}
                rows={tableRows}
                columns={TasksTableColumns}
                rowSelectionModel={selectedRows}
                loading={isFetching}
                getCellClassName={getCellClassName}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
                onChangePaginationPage={setPaginationPage}
            />
        </div>
    );
};

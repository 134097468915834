import React, { FC, useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { Control } from 'react-hook-form';

import { ControlledTextInput } from 'components/common/ControlledTextInput';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

type Props = {
    defaultValue: string;
    // eslint-disable-next-line
    control: Control<any>;
    setValue: (fieldName: 'selectedDates', value: string) => void;
};

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
    isSelected: boolean;
    isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered }) => ({
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary.light,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.light,
        },
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.primary.dark,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
    }),
    borderRadius: '50%',
})) as React.ComponentType<CustomPickerDayProps>;

function Day(
    props: PickersDayProps<Dayjs> & {
        selectedDays?: string[];
    },
) {
    const { day, selectedDays, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            selected={false}
            isSelected={(selectedDays || []).includes(day.format('DD.MM.YYYY'))}
            isHovered={false}
        />
    );
}

interface CustomDayProps extends PickersDayProps<Dayjs> {
    selectedDays: string[];
}

export const CustomRepeatCalendarDayField: FC<Props> = ({ control, setValue, defaultValue }) => {
    const [dates, setDates] = useState(defaultValue + ', ');

    const datesArray = dates.split(', ');

    const onSelectDate = (newValue: Dayjs) => {
        const dateString = newValue.format('DD.MM.YYYY');
        setDates((dates) => {
            const selected = datesArray.includes(dateString);
            const newDates = selected ? dates.replace(dateString + ', ', '') : dates + dateString + ', ';
            setValue('selectedDates', newDates);

            return newDates;
        });
    };

    return (
        <Box display={'flex'}>
            <div style={{ flex: '50%' }}>
                <ControlledTextInput
                    name={'selectedDates'}
                    control={control}
                    hint={
                        'Выберите даты для повтора мероприятия в календаре или укажите в поле ввода: в формате ДД.ММ.ГГГГ, через запятую и пробел '
                    }
                    label={''}
                    multiline
                    required
                />
            </div>
            <DateCalendar
                slots={{ day: Day }}
                slotProps={{ day: () => ({ selectedDays: datesArray }) as CustomDayProps }}
                showDaysOutsideCurrentMonth
                disablePast
                onChange={(newValue: Dayjs) => onSelectDate(newValue)}
            />
        </Box>
    );
};

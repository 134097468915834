import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AppContainer } from 'components/common/appContainer';
import { Routes } from 'navigation/routes';
import { useAsyncAppStore } from 'core/store/app/useAsyncAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { tabProps, UITabPanel } from 'components/UI/UITabs';
import { useTasksStore } from 'core/store/tasks/useTasksStore';
import { useUserStore } from 'core/store/user/useUserStore';
import { UserRoles } from 'core/constants/UserRoles';
import { MyTasks } from './components/MyTasks';
import { OverdueTasks } from './components/OverdueTasks';

const Tasks = () => {
    const { setOriginUrl } = useAsyncAppStore();
    const { user } = useUserStore();

    const [tabIndex, setTabIndex] = React.useState(0);
    const { setDateTo, setDateFrom } = useTasksStore((state) => state);

    useEffect(() => {
        setOriginUrl(Routes.Tasks);
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setDateFrom(null);
        setDateTo(null);
        setTabIndex(newValue);
    };

    const tabs = useMemo(() => {
        const tabsArr = [{ title: 'Мои задачи', component: <MyTasks /> }];

        if (user?.userRoles.map((role) => role.sysName).includes(UserRoles.project_manager)) {
            tabsArr.push({ title: 'Просроченные задачи', component: <OverdueTasks /> });
        }

        return tabsArr;
    }, []);

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.tasks.view}>
                <h2>Задачи</h2>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.title} {...tabProps(index)} />
                        ))}
                    </Tabs>
                </Box>
                {tabs.map((tab, index) => (
                    <UITabPanel key={index} value={tabIndex} index={index}>
                        {tab.component}
                    </UITabPanel>
                ))}
            </UserRightsContainer>
        </AppContainer>
    );
};

export { Tasks };

import { FC, useEffect, useMemo } from 'react';
import * as React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { AppContainer } from 'components/common/appContainer';
import { useAppStore } from 'core/store/app/useAppStore';
import { ControlledTextInput } from 'components/common/ControlledTextInput';
import { EventFormType } from 'types/events/EventFormType';
import { ControlledSelectInput } from 'components/common/ControlledSelectInput';
import { useEventsAdditionalData } from 'hooks/useEventsAdditionalData';
import { useEditEvent } from 'hooks/useEditEvent';
import { Routes } from 'navigation/routes';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { TreeViewSelectInput } from 'components/common/TreeViewSelectInput/TreeViewSelectInput';
import { useAdditionalData } from 'hooks/useAdditionalData';
import { ControlledCheckBoxInput } from 'components/common/ControlledCheckBoxInput';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { fillRiskFormDefaultValues } from 'utils/fillEventFormDefaultValues';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { ControlledDateInput } from 'components/common/ControlledDateInput';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { useUsers } from 'hooks/useUsers';
import { UserRoles } from 'core/constants/UserRoles';
import { EventFormDefaultValues } from 'core/constants/forms/EventFormDefaultValues';
import { RepetitiveEventForm } from '../components/RepetitiveEventForm';

const EditRepetitiveEvent: FC = () => {
    const navigate = useNavigate();
    const { riskId, eventId } = useParams();

    const setErrorAlertText = useAppStore((state) => state.setErrorAlertText);
    const { users } = useUsers(UserRoles.responsible_for_measure_implementation);
    const { dictionaryEventTypesInputOptions } = useEventsAdditionalData();
    const { dictionaryRiskSystemInputOptions } = useAdditionalData();
    const { event, editRepetitiveEvent } = useEditEvent({ riskId, eventId });

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: EventFormDefaultValues,
        shouldUseNativeValidation: false,
    });

    const watchFields = watch(['repeatEvent', 'repeatUnit']);
    const repeatEvent = watchFields[0];
    const eventRepeatUnit = watchFields[1];

    const responsibleUsers = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    useEffect(() => {
        if (event) {
            fillRiskFormDefaultValues(event, setValue);
        }
    }, [event]);

    const onSubmit: SubmitHandler<EventFormType> = (data) => {
        editRepetitiveEvent(data);
        setErrorAlertText('');
    };

    const onSave = (): void => {
        if (!isValid) {
            setErrorAlertText('Заполните все поля формы.');
        }
    };

    const onCancel = (): void => {
        if (!riskId) return;
        navigate(`${Routes.RiskEvents}/${riskId}`);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.events.editRepetitiveEvent}>
                <div className={'container_thin'}>
                    <h2>Редактирование мероприятия</h2>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} mb={'32px'}>
                            <ControlledSelectInput
                                control={control}
                                label={'Тип мероприятия'}
                                name={'eventTypeId'}
                                error={!!errors.eventTypeId}
                                options={dictionaryEventTypesInputOptions}
                                required
                                disabled
                            />
                            <ControlledTextInput
                                name={'eventText'}
                                control={control}
                                label={'Наименование мероприятия'}
                                error={!!errors.eventText}
                                required
                                disabled
                                multiline
                            />
                            <TreeViewSelectInput
                                control={control}
                                values={dictionaryRiskSystemInputOptions}
                                label={'Система/подсистема/узел'}
                                name={'systemSubsystemNode'}
                                error={!!errors.systemSubsystemNode}
                                required
                                disabled
                            />
                            <ControlledAutocompleteInput
                                control={control}
                                label={'Ответственный за исполнение мероприятия'}
                                name={'eventResponsible'}
                                error={!!errors.eventResponsible}
                                options={responsibleUsers}
                                required
                                disabled
                            />
                            <ControlledDateInput
                                control={control}
                                label={'Срок выполнения (плановая дата)'}
                                name={'dueDate'}
                                error={!!errors.dueDate}
                                required
                                disabled
                            />
                            <ControlledDateInput
                                control={control}
                                label={'Срок контроля мероприятия'}
                                name={'controlDate'}
                                error={!!errors.controlDate}
                                required
                                disabled
                            />
                            <ControlledCheckBoxInput
                                name={'repeatEvent'}
                                control={control}
                                label={'Повторять мероприятие'}
                            />
                            {repeatEvent && (
                                <RepetitiveEventForm
                                    defaultValue={event?.repetitiveEvent?.selectedDates?.join(', ') || ''}
                                    eventRepeatUnit={eventRepeatUnit}
                                    repeatEvent={repeatEvent}
                                    control={control}
                                    setValue={setValue}
                                />
                            )}
                            <div style={{ display: 'flex' }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={onSave}>
                                        Сохранить
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </div>
                        </Stack>
                    </form>
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { EditRepetitiveEvent };

import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { EVENTS, EVENTS_CSV } from 'core/http/endpoints';
import { axiosWithAuthHeader } from 'core/http';
import { EventType } from 'types/events/EventType';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { b64toBlob } from 'utils/b64toBlob';
import { downloadFile } from 'utils/downloadFile';
import { CSVRequestType } from 'types/csv/CSVRequestType';
import { PaginationType } from 'types/PaginationType';
import { EventsFilterDefaultValues, useEventsFilterStore } from 'core/store/events/useEventsFilterStore';
import { renderRepetitiveEventInfo } from '../utils/renderRepetitiveEventInfo';

const useEvents = (filterItems: boolean) => {
    const {
        filter,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
        setFilter,
        resetFilter,
        setDueDate,
        controlDate,
        setControlDate,
        dueDate,
    } = useEventsFilterStore();
    const { control, reset, getValues, setValue } = useForm({
        defaultValues: EventsFilterDefaultValues,
        shouldUseNativeValidation: false,
    });

    const [paginationPage, setPaginationPage] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [events, setEvents] = useState<EventType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setIsFetching(true);
        fetchEvents();
    }, [dateFrom, dateTo, paginationPage]);

    const onFilter = () => {
        fetchEvents();
    };

    useEffect(() => {
        setValue('eventTypeId', filter.eventTypeId);
        setValue('eventText', filter.eventText);
        setValue('responsibleUser', filter.responsibleUser);
        setValue('createdBy', filter.createdBy);
        setValue('statusId', filter.statusId);
        setValue('dueDate', dueDate ? dayjs(dueDate) : null);
        setValue('controlDate', controlDate ? dayjs(controlDate) : null);
    }, []);

    const getFilterParams = () => {
        const formData = getValues();

        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            ...(formData.eventTypeId && { eventTypeId: formData.eventTypeId }),
            ...(formData.eventText && { eventText: formData.eventText }),
            ...(formData.responsibleUser && { responsibleUser: formData.responsibleUser }),
            ...(formData.createdBy && { createdBy: formData.createdBy }),
            ...(formData.statusId && { statusId: formData.statusId }),
            ...(formData.dueDate && { dueDate: formData.dueDate.format('YYYY-MM-DD HH:mm:ss') }),
            ...(formData.controlDate && { controlDate: formData.controlDate.format('YYYY-MM-DD HH:mm:ss') }),
        };

        return filterItems ? params : { statusId: '10' };
    };

    const fetchEvents = () => {
        axiosWithAuthHeader
            .get<{ data: EventType[]; pagination: PaginationType }>(EVENTS, {
                params: { ...getFilterParams(), page: paginationPage },
            })
            .then((res) => {
                setEvents(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const onReset = () => {
        reset();
        resetFilter();
        fetchEvents();
    };

    const exportCSVEvents = () => {
        axiosWithAuthHeader
            .get<CSVRequestType>(EVENTS_CSV, { params: getFilterParams() })
            .then((res) => {
                const blob = b64toBlob(res.data.data, res.data.mimeType);
                downloadFile(blob, res.data.name);
            })
            .catch((error) => console.log(error));
    };

    const tableRows = useMemo(() => {
        return events?.map((event) => ({
            id: event.id,
            type: {
                eventName: event.eventType.name,
                repetitiveEventData: renderRepetitiveEventInfo(event),
            },
            text: event.eventText,
            responsible: event?.responsible ? userFullNameFromUser(event.responsible) : '',
            controlDate: event.controlDate,
            dueDate: event.dueDate,
            createdBy: userFullNameFromUser(event.createdBy),
            createDate: event.createDate,
            systemSubsystemNode: event?.riskSystem?.name,
            status: event.eventStatus.name,
        }));
    }, [events]);

    return {
        events,
        totalCount,
        tableRows,
        isFetching,
        onFilter,
        setDateFrom,
        setDateTo,
        control,
        onReset,
        setFilter,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        dateTo: dateTo ? dayjs(dateTo) : null,
        exportCSVEvents,
        setPaginationPage,
        setDueDate,
        setControlDate,
    };
};

export { useEvents };

import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import dayjs from 'dayjs';

import { EventType } from '../types/events/EventType';
import { EventFormDefaultValues } from '../core/constants/forms/EventFormDefaultValues';
import { userFullNameFromUser } from './userFullNameFromUser';

export const fillRiskFormDefaultValues = (
    event: EventType,
    setValue: UseFormSetValue<typeof EventFormDefaultValues>,
) => {
    setValue('eventTypeId', event.eventType.id.toString());
    setValue('eventText', event.eventText);
    setValue('systemSubsystemNode', event?.riskSystem?.id?.toString());
    if (event.responsible) {
        setValue('eventResponsible', {
            label: userFullNameFromUser(event.responsible),
            value: event.responsible.id.toString(),
        });
    }
    if (event.repetitiveEvent) {
        setValue('repeatEvent', true);
        setValue('repeatUnit', event.repetitiveEvent.responseUnit.name);
        if (event.repetitiveEvent.responseUnit.name === 'MONTH') {
            setValue('repeatDayOfMonth', event.repetitiveEvent.repeatDay);
        }
        if (event.repetitiveEvent.responseUnit.name === 'WEEK') {
            setValue('repeatDayOfWeek', event.repetitiveEvent.repeatDayOfWeek.name);
        }
        if (event.repetitiveEvent.responseUnit.name === 'YEAR') {
            setValue('repeatMonth', event.repetitiveEvent.repeatMonth);
            setValue('repeatDayOfMonth', event.repetitiveEvent.repeatDay);
        }
        if (event.repetitiveEvent.responseUnit.name === 'CUSTOM') {
            setValue('selectedDates', (event.repetitiveEvent?.selectedDates || []).join(', '));
        }
    }
    if (event.dueDate) {
        setValue('dueDate', dayjs(event.dueDate, 'DD.MM.YYYY'));
    }
    if (event.controlDate) {
        setValue('controlDate', dayjs(event.controlDate, 'DD.MM.YYYY'));
    }
};

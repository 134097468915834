import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { Typography } from '@mui/material';

export const EventsTableColumns: GridColDef[] = [
    {
        field: 'type',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Тип <br /> мероприятия
            </strong>
        ),
        renderCell: (params: { value?: { eventName?: string; repetitiveEventData?: string } }) => (
            <div>
                <Typography fontSize={12}>{params.value?.eventName}</Typography>
                <Typography fontSize={12} color="textSecondary">
                    {params.value?.repetitiveEventData}
                </Typography>
            </div>
        ),
    },
    {
        field: 'text',
        flex: 26,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Текст <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'systemSubsystemNode',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Система/подсистема/узел</strong>,
    },
    {
        field: 'responsible',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Ответственный за <br /> исполнение <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'dueDate',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Срок выполнения <br /> (плановая дата)
            </strong>
        ),
    },
    {
        field: 'controlDate',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => (
            <strong>
                Срок контроля <br /> мероприятия
            </strong>
        ),
    },
    {
        field: 'createdBy',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Кем создано</strong>,
    },
    {
        field: 'createDate',
        flex: 8,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Когда создано</strong>,
    },
    {
        field: 'status',
        flex: 10,
        disableColumnMenu: true,
        sortable: false,
        headerClassName: 'table-header',
        renderHeader: () => <strong>Статус</strong>,
    },
];

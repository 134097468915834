import { FC, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';

import { AppContainer } from 'components/common/appContainer';
import { UILoader } from 'components/UI/UILoader';
import { notificationsService } from 'core/services/notificationsService';
import { NotificationType } from 'types/notifications/NotificationType';
import { ViewNotificationComponent } from 'components/common/ViewNotificaionComponent';
import { ControlledAutocompleteInput } from 'components/common/ControlledAutocompleteInput';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { UserRoles } from 'core/constants/UserRoles';
import { Routes } from 'navigation/routes';
import { useAppStore } from 'core/store/app/useAppStore';
import { userRolesPermissions } from 'core/constants/userRolesPermissions';
import { UserRightsContainer } from 'components/common/UserRightsContainer';
import { useUsers } from 'hooks/useUsers';
import { useNavigateToOriginUrl } from 'hooks/useNavigateToOriginUrl';
import { TaskDeadlineModal } from 'components/common/TaskDeadlineModal/TaskDeadlineModal';
import { tasksService } from '../../../core/services/tasksService';

const AddRiskSpecialist: FC = () => {
    const { navigateToOriginalUrl } = useNavigateToOriginUrl();
    const { id } = useParams();
    const navigate = useNavigate();

    const { setErrorAlertText, setSuccessAlertText } = useAppStore((state) => state);

    const [notification, setNotification] = useState<NotificationType>();
    const [deadlineModalVisible, setDeadlineRejectModalVisible] = useState<boolean>(false);
    const [riskSpecialist, setRiskSpecialist] = useState<{ label: string; value: string | number } | null>(null);
    const { users } = useUsers(UserRoles.risk_specialist_subordinate);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            riskSpecialist: { label: '', value: '' },
        },
        shouldUseNativeValidation: false,
    });

    useEffect(() => {
        if (!id) return;

        notificationsService.getNotification(id, (res) => {
            const notification = res.data as NotificationType;
            setNotification(notification);
        });
    }, []);

    const riskSpecialists = useMemo(() => {
        return users.map((user) => ({ label: userFullNameFromUser(user), value: user.id }));
    }, [users]);

    const onSubmitForm = ({ riskSpecialist }: { riskSpecialist: { label: string; value: string | number } }) => {
        setDeadlineRejectModalVisible(true);
        setRiskSpecialist(riskSpecialist);
    };

    const onAddSpecialist = (dueDate: string) => {
        if (!id || !riskSpecialist) return;

        notificationsService.addRiskSpecialist(
            id,
            riskSpecialist.value,
            (res: { data: { taskId: number } }) => {
                const { taskId } = res.data;
                tasksService.setDueDate(dueDate, taskId, () => {
                    setSuccessAlertText('Специалист по рискам добавлен.');
                    navigateToOriginalUrl([Routes.Notifications, Routes.Tasks]);
                });
            },
            () => {
                setErrorAlertText('Ошибка при добавлении специалиста по рискам.');
            },
        );
    };

    const onCancel = (): void => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <UserRightsContainer allowedUserRoles={userRolesPermissions.notifications.addRiskSpecialist}>
                <div className={'container_thin'}>
                    <h2>Принять решение</h2>
                    {notification ? (
                        <div>
                            <ViewNotificationComponent notification={notification} />
                            <div className={'fields-container'}>
                                <span>Специалист по рискам:</span>
                                <ControlledAutocompleteInput
                                    control={control}
                                    label={'Специалист по рискам'}
                                    name={'riskSpecialist'}
                                    error={!!errors.riskSpecialist}
                                    options={riskSpecialists}
                                    required
                                />
                            </div>
                            <Box sx={{ display: 'flex', marginBottom: 4 }}>
                                <Box mr={'10px'}>
                                    <Button variant="contained" type={'submit'} onClick={handleSubmit(onSubmitForm)}>
                                        Назначить специалиста по рискам
                                    </Button>
                                </Box>
                                <Box mr={'10px'}>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Назад
                                    </Button>
                                </Box>
                            </Box>
                            <TaskDeadlineModal
                                isVisible={deadlineModalVisible}
                                setIsVisible={setDeadlineRejectModalVisible}
                                onSuccess={onAddSpecialist}
                            />
                        </div>
                    ) : (
                        <UILoader isVisible />
                    )}
                </div>
            </UserRightsContainer>
        </AppContainer>
    );
};

export { AddRiskSpecialist };

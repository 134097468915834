import * as React from 'react';
import { FC } from 'react';
import Select from '@mui/material/Select';
import { Control, Controller } from 'react-hook-form';
import { Dialog, FormControl, InputLabel, MenuItem } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

import { buildTreeRiskSystem } from 'utils/buildTreeRiskSystem';

type TreeNode = {
    value: number;
    label: string;
    parentId: number | null;
    childNodes: TreeNode[];
};

type ControlledSelectInputProps = {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    onChange?: (value: string) => void;
    // eslint-disable-next-line
    control: Control<any>;
    values: {
        value: number;
        label: string;
        parentId: number | null;
    }[];
};

export const TreeViewSelectInput: FC<ControlledSelectInputProps> = ({
    name,
    values,
    onChange,
    required,
    error,
    label,
    disabled,
    control,
}) => {
    const [open, setOpen] = React.useState(false);

    const tree = buildTreeRiskSystem(values);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const renderTree = (data: TreeNode[]) => {
        return data.map((item) => {
            return (
                <TreeItem key={item.value} itemId={item.value.toString()} label={item.label}>
                    {renderTree(item.childNodes)}
                </TreeItem>
            );
        });
    };

    const handleItemSelectionToggle = (
        field: ControllerRenderProps,
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        if (isSelected) {
            field.onChange(Number.parseInt(itemId));
            onChange && onChange(itemId);
            setOpen(false);
        }
    };

    const findAllParentIds = (data: { value: number; parentId: number | null }[], id: number): string[] => {
        const lookup = new Map(data.map((item) => [item.value, item.parentId]));
        const parentIds: string[] = [];

        let currentId = lookup.get(id);
        while (currentId !== null && currentId !== undefined) {
            parentIds.push(currentId.toString());
            currentId = lookup.get(currentId);
        }

        return parentIds;
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field }) => {
                const allParentIds = findAllParentIds(values, Number.parseInt(String(field.value)));

                return (
                    <FormControl size={'small'} error={error} required={required} fullWidth>
                        <InputLabel error={error} required={required}>
                            {label}
                        </InputLabel>
                        <Select
                            inputRef={field.ref}
                            value={field.value as string}
                            label={label}
                            open={false}
                            disabled={disabled}
                            onOpen={handleOpen}
                            onClose={handleClose}
                        >
                            {values.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Dialog open={open} fullWidth onClose={handleClose}>
                            <SimpleTreeView
                                selectedItems={String(field?.value)}
                                defaultExpandedItems={allParentIds}
                                checkboxSelection
                                onItemSelectionToggle={(event, itemId, isSelected) => {
                                    handleItemSelectionToggle(field, event, itemId, isSelected);
                                }}
                            >
                                {renderTree(tree)}
                            </SimpleTreeView>
                        </Dialog>
                    </FormControl>
                );
            }}
        />
    );
};

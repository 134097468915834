import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OVERDUE_TASKS, TASKS } from 'core/http/endpoints';
import { axiosWithAuthHeader } from 'core/http';
import { userFullNameFromUser } from 'utils/userFullNameFromUser';
import { useTasksStore } from 'core/store/tasks/useTasksStore';
import { TaskType } from 'types/tasks/TaskType';
import { PaginationType } from 'types/PaginationType';
import { Routes } from 'navigation/routes';
import { TaskSysName } from 'core/constants/TaskSysName';

const useTasksPage = () => {
    const navigate = useNavigate();
    const { dateFrom, dateTo, setDateTo, setDateFrom } = useTasksStore((state) => state);

    const [tasks, setTasks] = useState<TaskType[]>([]);
    const [overDueTasks, setOverDueTasks] = useState<TaskType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [paginationPage, setPaginationPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setIsFetching(true);
        fetchMyTasks();
        fetchOverDueTasks();
    }, [dateFrom, dateTo, paginationPage]);

    const fetchOverDueTasks = () => {
        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            page: paginationPage,
        };

        axiosWithAuthHeader
            .get<{ data: TaskType[]; pagination: PaginationType }>(OVERDUE_TASKS, { params })
            .then((res) => {
                setOverDueTasks(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const fetchMyTasks = () => {
        const params = {
            dateFrom: dateFrom ? dayjs(dateFrom)?.startOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            dateTo: dateTo ? dayjs(dateTo)?.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null,
            page: paginationPage,
        };

        axiosWithAuthHeader
            .get<{ data: TaskType[]; pagination: PaginationType }>(TASKS, { params })
            .then((res) => {
                setTasks(res.data.data);
                setTotalCount(res.data.pagination.totalCount);
                setIsFetching(false);
            })
            .catch((error) => console.log(error));
    };

    const tableRows = useMemo(() => {
        return tasks?.map((task) => ({
            id: task.id,
            name: task.text,
            object: task.object.objectName,
            status: task.taskStatus,
            createdBy: userFullNameFromUser(task.createdBy),
            createdAt: task.createDate,
            dueDate: task.dueDate,
        }));
    }, [tasks]);

    const tableRowsOverdueTasks = useMemo(() => {
        return overDueTasks?.map((task) => ({
            id: task.id,
            name: task.text,
            object: task.object.objectName,
            status: task.taskStatus,
            createdBy: userFullNameFromUser(task.createdBy),
            createdAt: task.createDate,
            dueDate: task.dueDate,
        }));
    }, [overDueTasks]);

    const accomplishTask = (task?: TaskType) => {
        if (!task) return;
        let link = `${Routes.RiskEvents}/${task.object.objectId}`;
        switch (task.sysName) {
            case TaskSysName.ApproveNotification: {
                link = `${Routes.ApproveNotification}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.AppointRiskSpecialist: {
                link = `${Routes.NotificationAddRiskSpecialist}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.CreateRiskFromNotification: {
                link = `${Routes.NotificationRiskDecision}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.ApproveRisk: {
                link = `${Routes.ApproveRisk}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.AppointRiskOwner: {
                link = `${Routes.AddRiskOwner}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.EditAfterProjectManagerRevision: {
                link = `${Routes.SendRiskToApprove}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.Assess: {
                link = `${Routes.AssessRisk}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.CheckBeforeApprovingByProjectManager: {
                link = `${Routes.ApproveRiskAssessment}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.ApproveAssessment: {
                link = `${Routes.CheckRiskAssessment}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.EditRiskAssessmentAfterProjectManagerRevision: {
                link = `${Routes.AssessRisk}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.ApproveRequirement: {
                link = `${Routes.ApproveRequirement}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.ConfirmRequirement: {
                link = `${Routes.ConfirmRequirement}/${task.object.objectId}`;
                break;
            }
            case TaskSysName.EditAfterCoordinatorRevision: {
                link = `${Routes.ApproveRiskAssessment}/${task.object.objectId}`;
                break;
            }
        }
        navigate(link);
    };

    return {
        tasks,
        tableRows,
        isFetching,
        overDueTasks,
        tableRowsOverdueTasks,
        dateFrom: dateFrom ? dayjs(dateFrom) : null,
        setDateFrom,
        dateTo: dateTo ? dayjs(dateTo) : null,
        setDateTo,
        setPaginationPage,
        totalCount,
        accomplishTask,
        fetchOverDueTasks,
    };
};

export { useTasksPage };
